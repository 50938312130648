import { Accordion } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import config from "configs/config.js";
import api from "services/api";
import auth from "services/auth";
import { Overlay, Tooltip } from "react-bootstrap";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function ReferAndEarn(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [userDetails, setUserDetails] = useState(auth.getUser);
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-referred-player");
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const [appUrl, setAppUrl] = useState(config.appUrl);
  const [referralUrl, setReferralUrl] = useState(
    appUrl + "/refer/" + userDetails.username
  );

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const copyReferralUrl = () => {
    var copyText = document.getElementById("referralUrl");
    // var copyText = document.getElementById("myInput");

    /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <PageHeadline page_name={__("Refer And Earn")} />

      <div className="page_content_area">
        <div className="referal_url_area">
          <div className="input_item">
            <div className="name">{__("Copy URL and send it to friends")}</div>
            <input
              className="form-control name_value"
              type="text"
              readonly="readonly"
              name="referralUrl"
              id="referralUrl"
              value={referralUrl}
            />
            <a
              className="in_input_icon"
              onClick={copyReferralUrl}
              href="javascript:"
              ref={target}
              // onClick={() => setShow(!show)}
            >
              <i class="fas fa-copy"></i>
            </a>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" className="tooltip" {...props}>
                  {__("Copied")}
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>
        <div className="d-none d-sm-block">
          <div className="heading_row game_history">
            <div className="item">{__("Player Nickname")}</div>
          </div>
          {results.length ? (
            <>
              {results.map((item, index) => (
                <div key={index} className="data_row">
                  <div className="item">
                    <span className="action_icon">
                      <i className="far fa-check"></i>
                    </span>
                    {item.player_details.player_nickname}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>

        <div className="mobile_accordion d-block d-sm-none">
          {results.length ? (
            <>
              <Accordion>
                {results.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.game?.game_name}</Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion_content">
                        <div className="input_item">
                          <div className="name">{__("Game")}</div>
                          <div className="form-control name_value">
                            {item.game?.game_name}
                          </div>
                        </div>
                        <div className="input_item">
                          <div className="name">{__("Type")}</div>
                          <div className="form-control name_value">{item.type}</div>
                        </div>
                        <div className="input_item">
                          <div className="name">
                            {__("Balance Type")}
                          </div>
                          <div className="form-control name_value">
                            {item.using_bonus}
                          </div>
                        </div>
                        <div className="input_item">
                          <div className="name">{__("Freeround")}</div>
                          <div className="form-control name_value">
                            {item.is_freeround}
                          </div>
                        </div>
                        <div className="input_item">
                          <div className="name">{__("Amount")}</div>
                          <div className="form-control name_value">{item.amount}</div>
                        </div>

                        <div className="input_item">
                          <div className="name">
                            {__("Cash Balance Before")}
                          </div>
                          <div className="form-control name_value">
                            {item.balance_before}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>

              <br />
              <br />
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
        <br />
      </div>
    </>
  );
}
