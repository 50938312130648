import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory, useParams } from "react-router-dom";
import auth from "../services/auth";
import Spinner from "../elements/Spinner";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
// import passwordValidate from "../services/passwordValidate";
import Translator, { __ } from "services/Translator";

import validator from "validator";
import swal from "sweetalert";

// import SocialLogin from "./SocialLogin";
import { Modal, Button, Alert } from "react-bootstrap";

const LoginReg = (props) => {
  const params = useParams();
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  // loginModal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const openLoginModal = () => {
    setShowLoginModal(true);
    setActiveTab("login");
    // setShowSidebar(false);
  };
  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  //SignUp Modal
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const openSignUpModal = () => {
    // setShowSignUpModal(true);
    setActiveTab("Sign up");
    setShowLoginModal(true);
    // setShowSidebar(false);
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
  };

  // tabs
  const [activeTab, setActiveTab] = useState("login");
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  // recoverModal
  const [recoverPssModal, setRecoverPssModal] = useState(false);
  const openRecoverPssModal = () => {
    setRecoverPssModal(true);
    setShowLoginModal(false);
    setSentForgotPassMail(false);
  };
  const closeRecoverPssModal = () => {
    setRecoverPssModal(false);
  };

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userType, setUserType] = useState(auth.getUserType);
  const [modalForgotPassword, setModalForgotPassword] = useState(
    props.modalForgotPassCall
  );

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const [action, setAction] = useState(props.action ? props.action : "signup"); //signup, login depending on account exsitance
  const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu);

  const [modalLogin, setModalLogin] = useState(props.modalLoginCall);
  const [modalLoginCall, setModalLoginCall] = useState(false);

  const [initModalLoginProps, setInitModalLoginProps] = useState({
    loging_modal: false,
    forgot_pass_modal: false,
  });

  const [loginData, setLoginData] = useState({
    loging_modal: false,
    forgot_pass_modal: false,
  });
  const switchToLogin = () => {
    closeAllModals();
    // setModalLogin(true);
    setShowLoginModal(true);
  };

  const switchToForgotPassword = () => {
    closeAllModals();
    setModalForgotPassword(true);
    setSentForgotPassMail(false);
  };

  const closeAllModals = () => {
    // setModalLogin(false);
    setShowLoginModal(true);
    setModalForgotPassword(false);
  };

  useEffect(() => {
    // setModalLogin(props.modalLoginPropsData);
    // alert(props.modalLoginPropsData)
    if (props.modalLoginPropsData == true) {
      setShowLoginModal(props.modalLoginPropsData);
      setActiveTab("login");
      setModalForgotPassword(props.modalForgotPasswordPropsData);
    }

    console.log("props.modalSignUpPropsData", props.modalSignUpPropsData);
    if (props.modalSignUpPropsData == true) {
      setShowLoginModal(props.modalSignUpPropsData);
      setActiveTab("Sign up");
      setModalForgotPassword(props.modalForgotPasswordPropsData);
    }
  }, [props]);

  const updateEmail = (ev) => {
    setEmail(ev.target.value);
  };
  const updatePass = (ev) => {
    setPass(ev.target.value);
  };

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [mainErrorMgs, setMainErrorMgs] = useState("");

  const updateForm = (field, value) => {
    setLoginForm({ ...loginForm, [field]: value });
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
  };

  const updateSelect = (a, b) => {
    var field = b.name;
    var value = a.value;
    updateForm(field, value);
  };

  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    // if (!emailValidate(loginForm.email)) {
    //   errorsTmp = { ...errorsTmp, email: "Please enter a valid email address" };
    //   valid = false;
    // }

    if (loginForm.email.length < 2 || loginForm.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (loginForm.password.length < 2 || loginForm.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter your password") };
      valid = false;
    }

    setErrors(errorsTmp);

    return valid;
  };

  const login = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();
    setMainErrorMgs("");
    if (valid) {
      setSpinner(true);
      var response = await api.post("/login", loginForm);
      console.log(response);
      setSpinner(false);
      if (response.status == 200) {
        await auth.login(response.data.user);
        await updateUserObj(response.data.user);
        // setRegistrationSuccessModal(true);
        setShowLoginModal(false);
        setIsAuthenticated(auth.isAuthenticated);
        swal({
          title: __("Welcome"),
          className: props.theme,
          text: __("Let's go play!"),
          icon: "success",
          // timer: 5000,
        });
      } else {
        // setAlertMsg({
        //   message: response.data.message,
        //   variant: "danger",
        //   showAlert: true,
        // });

        // setTimeout(() => {
        //   setAlertMsg({
        //     message: response.data.message,
        //     variant: "success",
        //     showAlert: false,
        //   });
        // }, 5000);

        swal({
          className: props.theme,
          title: __("Login Failed"),
          text: response.data.message,
          icon: "error",
        });
      }
    }
  };

  //Getting necessary data for registration START'S
  const [allCountries, setAllCountries] = useState([]);
  const getAllCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setAllCountries(response.data);
    }
  };

  const [languages, setLanguages] = useState([]);
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200 && response.data) {
      setLanguages(response.data);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };

  const [regCampaigns, setRegCampaigns] = useState([]);
  const getRegCampaigns = async () => {
    var response = await api.post("/get-registration-campaigns", {
      country: registrationForm.country,
      currency: registrationForm.currency,
    });
    if (response.status === 200 && response.data) {
      setRegCampaigns(response.data);
    }
  };

  useEffect(() => {
    // getAllCountries();
    // getLanguages();
    // getCurrencies();
    // getRegCampaigns();
  }, []);

  //Getting necessary data for registration END'S

  //Register functionality START'S here
  const [activeStep, setActiveStep] = useState(1);
  const [showPass, setShowPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);
  const [regErrors, setRegErrors] = useState({});
  // const [registrationForm, setRegistrationForm] = useState({
  //   firstName: "",
  //   lastName: "",
  //   username:"",
  //   email: "",
  //   password: "",
  //   rePassword: "",
  //   dateOfBirth: "",
  //   agreeToTerms: 0,
  //   receiveBonusOffer: 0,
  // });

  const [registrationForm, setRegistrationForm] = useState({
    username: "",
    email: "",
    password: "",
    bday: "",
    bmonth: "",
    byear: "",
    code: "",
    referralCode: "",
    promoCode: "",
  });

  const updateRegistrationForm = (field, value) => {
    setRegistrationForm({ ...registrationForm, [field]: value });
    console.log("updateRegistrationForm", registrationForm);
  };

  const updateRegInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    if (type == "checkbox") {
      if (target.checked) {
        updateRegistrationForm(field, 1);
      } else {
        updateRegistrationForm(field, 0);
      }
    } else {
      updateRegistrationForm(field, value);
    }
  };

  const checkRegDuplicateEmail = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-email", {
      email: registrationForm.email,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const checkRegDuplicateUsername = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-username", {
      username: registrationForm.username,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const validateRegisterForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);

    if (
      registrationForm.username.length < 2 ||
      registrationForm.username.length > 250
    ) {
      errorsTmp = { ...errorsTmp, username: __("Please enter the user name") };
      valid = false;
    } else {
      var response = checkRegDuplicateUsername(registrationForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            username: val.username,
          };

          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    // if (
    //   registrationForm.firstName.length < 2 ||
    //   registrationForm.firstName.length > 250
    // ) {
    //   errorsTmp = { ...errorsTmp, firstName: "Please enter first name" };
    //   valid = false;
    // }
    // if (
    //   registrationForm.lastName.length < 2 ||
    //   registrationForm.lastName.length > 250
    // ) {
    //   errorsTmp = { ...errorsTmp, lastName: "Please enter last name" };
    //   valid = false;
    // }

    if (
      registrationForm.email.length < 2 ||
      registrationForm.email.length > 250
    ) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (!emailValidate(registrationForm.email)) {
      errorsTmp = {
        ...errorsTmp,
        email: __("Please enter a valid email address"),
      };
      valid = false;
    } else {
      var response = checkRegDuplicateEmail(registrationForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            email: val.email,
          };

          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    // if (
    //   registrationForm.dateOfBirth.length < 2 ||
    //   registrationForm.dateOfBirth.length > 250
    // ) {
    //   errorsTmp = { ...errorsTmp, dateOfBirth: "Please enter your birth day" };
    //   valid = false;
    // }

    if (
      registrationForm.password.length < 8 ||
      registrationForm.password.length > 30
    ) {
      errorsTmp = {
        ...errorsTmp,
        password: __("Your password must contain between 8 and 30 characters"),
      };
      valid = false;
    }

    // if (registrationForm.password != registrationForm.rePassword) {
    //   errorsTmp = {
    //     ...errorsTmp,
    //     rePassword: "please make sure both passwords match.",
    //   };
    //   valid = false;
    // }

    // if (!registrationForm.agreeToTerms) {
    //   errorsTmp = {
    //     ...errorsTmp,
    //     agreeToTerms: "Please accept the terms and conditions",
    //   };
    //   valid = false;
    // }

    setRegErrors(errorsTmp);
    return valid;
  };

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = validateRegisterForm();
    if (valid) {
      setSpinner(true);
      var response = await api.post("/stake-signup", registrationForm);
      setSpinner(false);
      console.log(response.data);
      if (response.status == 200) {
        setShowLoginModal(false);
        // history.push("/registration-complete");

        swal({
          className: props.theme,
          title: __("Congratulations!"),
          text: __(
            "Your account just created, please check your email and verify your account."
          ),
          icon: "success",
        });
        // history.push("/login");
        setActiveTab("login");
      } else {
        // swal({
        //   title: "Registration Failed",
        //   text: response.data.message,
        //   icon: "error",
        // });
        if (response.data.errors) {
          setRegErrors(response.data.errors);
        } else {
          alert(response.data.message);
        }
      }
    }
  };
  //Register functionality END'S here

  //forgot Password functionality START'S here
  const [forGotErrors, setForGotErrors] = useState("");
  const [forgotPassForm, setForgotPassForm] = useState({
    email: "",
  });
  const [sentForgotPassMail, setSentForgotPassMail] = useState(false);

  const updateForgotInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setForgotPassForm({
      email: value,
    });
  };

  const forgotPassword = async () => {
    var errorsTmp = {};

    if (!emailValidate(forgotPassForm.email)) {
      setForGotErrors("Please enter a valid email address");
    } else {
      var response = await api.post("/request-reset-password", forgotPassForm);
      console.log(response);
      if (response.status == 200) {
        // setRegistrationSuccessModal(true);
        setSentForgotPassMail(true);
      } else {
        setForGotErrors(response.data.message);
      }
    }
  };
  //forgot password functionality END'S here

  // const params = queryString.parse(window.location.search).p;

  //Referral functionality here
  const [referralCode, setReferralCode] = useState(params.referral_code);
  const getReferrerInfo = async () => {
    var response = await api.post("/get-referrer-info", {
      referralCode: referralCode,
    });
    if (response.status === 200) {
      setRegistrationForm({
        ...registrationForm,
        ["referralCode"]: response.data.referrer_info.referral_code,
      });
    }
  };

  useEffect(async () => {
    if (typeof referralCode !== "undefined") {
      setShowLoginModal(true);
      setActiveTab("Sign up");
      getReferrerInfo();
    }
  }, [referralCode]);

  return (
    <>
      {spinner && <Spinner />}
      <Modal size="lg" show={showLoginModal} onHide={closeLoginModal}>
        <Modal.Body className={"login_modal " + props.theme}>
          <div onClick={closeLoginModal} className="modal_close">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20L19.3848 1.61522"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M1 2L19.3848 20.3848"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div class="login_mode ">
            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              to="#"
              onClick={() => toggleTab("login")}
              className={activeTab === "login" ? "mode_btn active" : "mode_btn"}
            >
              {__("login")}
            </Link>
            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              to="#"
              onClick={() => toggleTab("Sign up")}
              className={
                activeTab === "Sign up" ? "mode_btn active" : "mode_btn"
              }
            >
              {__("Sign up")}
            </Link>
          </div>

          <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
            {alertMsg.message}
          </Alert>

          {activeTab === "login" && (
            <div className="form_area">
              <form onSubmit={login}>
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <br />
                    <div className="input_item">
                      <div className="name">
                        {__("Username")} {__("or")} {__("Email")}
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="text"
                        name="email"
                        placeholder={__("Username")}
                        value={loginForm.email}
                        onChange={updateInput}
                        autocomplete="off"
                        class={
                          "form-control name_value " +
                          (errors.email ? "error" : "")
                        }
                      />
                      <div class="errorMsg">{errors.email}</div>
                    </div>
                    <div className="input_item">
                      <div className="name">
                        {__("Password")}
                        <span className="requried_icon">*</span>
                        <span
                          onClick={openRecoverPssModal}
                          className="forgot_pass_link"
                        >
                          {__("Forgot?")}
                        </span>
                      </div>
                      <input
                        type="password"
                        name="password"
                        placeholder={__("Password")}
                        className={
                          "form-control name_value " +
                          (errors.password ? "error" : "")
                        }
                        value={loginForm.password}
                        autoComplete="nope"
                        onChange={updateInput}
                      />

                      <div className="errorMsg">{errors.password}</div>
                    </div>
                  </div>
                </div>

                <div className="login_button">
                  <button
                    type="submit"
                    className="common_btn_v1 fit_width  margin_auto"
                  >
                    {__("Log in")}
                  </button>
                </div>
              </form>
            </div>
          )}

          {activeTab === "Sign up" && (
            <>
              <form onSubmit={registrationAction}>
                {activeStep === 1 && (
                  <div className="form_area">
                    <div className="row">
                      <div className="col-lg-10 offset-lg-1">
                        <div className="modal_headline">{__("Welcome")}</div>
                        <div className="input_item">
                          <div className="name">
                            {__("Username")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="text"
                            name="username"
                            placeholder={__("Username")}
                            onChange={updateRegInput}
                            value={registrationForm.username}
                            className={
                              "form-control name_value " +
                              (regErrors.username ? "error" : "")
                            }
                          />
                          <div class="errorMsg">{regErrors.username}</div>
                        </div>
                        <div className="input_item">
                          <div className="name">
                            Password<span className="requried_icon">*</span>
                          </div>
                          <input
                            type={showPass ? "text" : "password"}
                            name="password"
                            placeholder={__("Password")}
                            onChange={updateRegInput}
                            value={registrationForm.password}
                            className={
                              "form-control name_value " +
                              (regErrors.password ? "error" : "")
                            }
                          />

                          {showPass && (
                            <i
                              class="show_hide_pass fa fa-eye-slash"
                              onClick={() => {
                                setShowPass(false);
                              }}
                              aria-hidden="true"
                            ></i>
                          )}
                          {!showPass && (
                            <i
                              className="show_hide_pass fa fa-eye"
                              onClick={() => {
                                setShowPass(true);
                              }}
                              aria-hidden="true"
                            ></i>
                          )}

                          <div class="errorMsg">{regErrors.password}</div>
                        </div>

                        <div className="input_item">
                          <div className="name">
                            {__("Date of birth")}
                            <span className="requried_icon">*</span>
                          </div>
                          <div className="three_fields">
                            <input
                              type="number"
                              name="bday"
                              placeholder={__("Day")}
                              onChange={updateRegInput}
                              value={registrationForm.bday}
                              step="1"
                              min="1"
                              max="31"
                              className={
                                "form-control name_value " +
                                (regErrors.date ? "error" : "")
                              }
                            />
                            <input
                              type="number"
                              name="bmonth"
                              placeholder={__("Month")}
                              onChange={updateRegInput}
                              value={registrationForm.bmonth}
                              step="1"
                              min="1"
                              max="12"
                              className={
                                "form-control name_value " +
                                (regErrors.month ? "error" : "")
                              }
                            />
                            <input
                              type="number"
                              name="byear"
                              placeholder={__("Year")}
                              onChange={updateRegInput}
                              value={registrationForm.byear}
                              step="1"
                              min="1900"
                              className={
                                "form-control name_value " +
                                (regErrors.year ? "error" : "")
                              }
                            />
                          </div>

                          <div class="errorMsg">{regErrors.dateOfBirth}</div>
                        </div>

                        <div className="input_item">
                          <div className="name">{__("Currency")}</div>
                          <select
                            className={
                              "form-select name_value " +
                              (regErrors.email ? "error" : "")
                            }
                          >
                            <option>{__("USD")}</option>
                            <option>{__("CAD")}</option>
                            <option>{__("EUR")}</option>
                            <option>{__("DZD")}</option>
                          </select>

                          <div class="errorMsg">{regErrors.currency}</div>
                        </div>

                        <div className="input_item">
                          <div className="name">
                            {__("Email")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="email"
                            name="email"
                            placeholder={__("Email")}
                            onChange={updateRegInput}
                            value={registrationForm.email}
                            className={
                              "form-control name_value " +
                              (regErrors.email ? "error" : "")
                            }
                          />
                          <div class="errorMsg">{regErrors.email}</div>
                        </div>

                        <div className="input_item">
                          <div className="name">
                            {__("Referral Code")} ({__("Optional")})
                          </div>
                          <input
                            type="text"
                            name="referralCode"
                            placeholder={__("Referral Code")}
                            onChange={updateRegInput}
                            value={registrationForm.referralCode}
                            className={
                              "form-control name_value " +
                              (regErrors.referralCode ? "error" : "")
                            }
                          />
                        </div>

                        <div className="input_item">
                          <div className="name">
                            {__("Promo Code")} ({__("Optional")})
                          </div>
                          <input
                            type="text"
                            name="promoCode"
                            placeholder={__("Promo Code")}
                            onChange={updateRegInput}
                            value={registrationForm.promoCode}
                            className={
                              "form-control name_value " +
                              (regErrors.promoCode ? "error" : "")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="desc">
                      {__(
                        "By clicking Play Now, you are indicating that you have read and acknowledge the "
                      )}
                      <Link
                        hrefLang={Translator.getSelectedLanguage().code}
                        to="#"
                      >
                        {__("Terms & Conditions")}
                      </Link>
                    </div>

                    <div className="login_button">
                      <button
                        type="submit"
                        className="common_btn_v1 fit_width  margin_auto"
                      >
                        {__("Play Now")}
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </>
          )}

          {props.theme === "light" ? (
            <div className="watermark">
              <img
                src={require("../assets/images/v1/login-watermark.svg").default}
              />
            </div>
          ) : (
            <div className="watermark">
              <img
                src={require("../assets/images/v1/login-watermark-dark.svg").default}
              />
            </div>
          )}

          <div className="privacy_text">
            {__("This site is protected by Captcha and the Captcha")}{" "}
            <Link
              target="_blank"
              hrefLang={Translator.getSelectedLanguage().code}
              to="/privacy-policy"
            >
              {__("Privacy Policy")}
            </Link>{" "}
            {__("and")}{" "}
            <Link
              target="_blank"
              hrefLang={Translator.getSelectedLanguage().code}
              to="/terms-and-conditions"
            >
              {__("Terms of Service")}
            </Link>{" "}
            {__("apply")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={recoverPssModal} onHide={closeRecoverPssModal}>
        <Modal.Body className={"login_modal forgot_pass " + props.theme}>
          <div onClick={closeRecoverPssModal} className="modal_close">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20L19.3848 1.61522"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M1 2L19.3848 20.3848"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div className="modal_headline">{__("Password Reset")}</div>
          {!sentForgotPassMail && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">
                    {__("Email")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={forgotPassForm.email}
                    onChange={updateForgotInput}
                    className={
                      "form-control name_value " + (forGotErrors ? "error" : "")
                    }
                  />
                  <div class="errorMsg">{forGotErrors}</div>
                </div>

                <div className="login_button">
                  <button
                    onClick={forgotPassword}
                    className="common_btn_v1 width_100"
                  >
                    {__("Send Password Reset Link")}
                  </button>
                </div>
              </div>
            </>
          )}

          {sentForgotPassMail && (
            <p className="recovery_success">
              {__(
                "A password recovery email has been sent to your provided email address."
              )}
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginReg;
