import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

export default function VipRewards() {
  return (
    <div className="container">
      <div className="vip_rewards">
        <div className="heading">{__("Level up your Rewards!")}</div>
        {/* <div className="title col-lg-8 offset-lg-2">
          {__(
            "The more you play, the higher your level will grow. Reach the top with Diamond VIP status and enjoy unmatched service and extras like no other online casino offers. Get ready for the ultimate gaming adventure!"
          )}
        </div> */}
        <div className="reward_data">
          <div className="single_datarow header_part">
            <div className="item text-center">
              <img src={require("assets/images/v1/vip/level/1.svg").default} />
            </div>
            <div className="item text-center">
              <img src={require("assets/images/v1/vip/level/2.svg").default} />
            </div>
            <div className="item text-center">
              <img src={require("assets/images/v1/vip/level/3.svg").default} />
            </div>
            <div className="item text-center">
              <img src={require("assets/images/v1/vip/level/4.svg").default} />
            </div>
            <div className="item text-center">
              <img src={require("assets/images/v1/vip/level/5.svg").default} />
            </div>
          </div>

          <div className="single_datarow">
            <div className="item text-uppercase">
              <strong>{__("benefits")}</strong>
            </div>
            <div className="item text-uppercase ">
              <strong>{__("benefits")}</strong>
            </div>
            <div className="item text-uppercase ">
              <strong>{__("benefits")}</strong>
            </div>
            <div className="item text-uppercase ">
              <strong>{__("benefits")}</strong>
            </div>
            <div className="item text-uppercase ">
              <strong>{__("benefits")}</strong>
            </div>
          </div>

          <div className="single_datarow new_design">
            <div className="item">
              <div className="item_name">{__("Weekly Withdrawal Limit")}</div>
              <div className="item_name">{__("$8,000")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Weekly Withdrawal Limit")}</div>
              <div className="item_name">{__("$12,000")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Weekly Withdrawal Limit")}</div>
              <div className="item_name">{__("$16,000")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Weekly Withdrawal Limit")}</div>
              <div className="item_name">{__("$20,000")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Weekly Withdrawal Limit")}</div>
              <div className="item_name">{__("$30,000")}</div>
            </div>
          </div>
          <div className="single_datarow new_design">
            <div className="item">
              <div className="item_name">
                {__("Comp Points per $20 wagered")}
              </div>
              <div className="item_name">{__("Up to 1")}</div>
            </div>
            <div className="item">
              <div className="item_name">
                {__("Comp Points per $20 wagered")}
              </div>
              <div className="item_name">{__("Up to 1.33")}</div>
            </div>
            <div className="item">
              <div className="item_name">
                {__("Comp Points per $20 wagered")}
              </div>
              <div className="item_name">{__("Up to 2")}</div>
            </div>
            <div className="item">
              <div className="item_name">
                {__("Comp Points per $20 wagered")}
              </div>
              <div className="item_name">{__("Up to 4")}</div>
            </div>
            <div className="item">
              <div className="item_name">
                {__("Comp Points per $20 wagered")}
              </div>
              <div className="item_name">{__("Up to 5")}</div>
            </div>
          </div>
          <div className="single_datarow new_design">
            <div className="item">
              <div className="item_name">{__("Personal Account Manager")}</div>
              <div className="item_name">❌</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Personal Account Manager")}</div>
              <div className="item_name">❌</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Personal Account Manager")}</div>
              <div className="item_name">❌</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Personal Account Manager")}</div>
              <div className="item_name">☑️</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Personal Account Manager")}</div>
              <div className="item_name">☑️</div>
            </div>
          </div>

          <div className="single_datarow new_design">
            <div className="item">
              <div className="item_name">{__("Monthly Cashback")}</div>
              <div className="item_name">{__("5%  on losses")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Monthly Cashback")}</div>
              <div className="item_name">{__("6%  on losses")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Monthly Cashback")}</div>
              <div className="item_name">{__("7%  on losses")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Monthly Cashback")}</div>
              <div className="item_name">{__("8%  on losses")}</div>
            </div>
            <div className="item">
              <div className="item_name">{__("Monthly Cashback")}</div>
              <div className="item_name">{__("10% on losses")}</div>
            </div>
          </div>

          <div className="single_datarow">
            <div className="item">
              <div className="item_heading">{__("Conditions")}</div>
              <ul>
                <li>{__("You can convert your points (minimum 100).")}</li>
                <li>
                  {__(
                    "You receive up to $/€1 for each 100 points you convert."
                  )}
                </li>
                <li>
                  {__(
                    "No wagering, 50x maximum cash out limitations on converted point."
                  )}
                </li>
                <li>
                  {__(
                    "Mixing Comp Points with other Fund Actions is not allowed."
                  )}
                </li>
                <li>
                  {__(
                    "Comp Points can only be redeemed if balance is less than $1."
                  )}
                </li>
              </ul>
              <div className="links ">
                <Link className="common_btn_v1 fit_width margin_auto">
                  {__("Sign Up")}
                </Link>
              </div>
            </div>
            <div className="item">
              <div className="item_heading">{__("Conditions")}</div>
              <ul>
                <li>{__("You can convert your points (minimum 100).")}</li>
                <li>
                  {__(
                    "You receive up to $/€1 for each 100 points you convert."
                  )}
                </li>
                <li>
                  {__(
                    "No wagering, 50x maximum cash out limitations on converted point."
                  )}
                </li>
                <li>
                  {__(
                    "Mixing Comp Points with other Fund Actions is not allowed."
                  )}
                </li>
                <li>
                  {__(
                    "Comp Points can only be redeemed if balance is less than $1."
                  )}
                </li>
              </ul>
              <div className="links ">
                <Link className="common_btn_v1 fit_width margin_auto">
                  {__("Sign Up")}
                </Link>
              </div>
            </div>
            <div className="item">
              <div className="item_heading">{__("Conditions")}</div>
              <ul>
                <li>{__("You can convert your points (minimum 100).")}</li>
                <li>
                  {__(
                    "You receive up to $/€1 for each 100 points you convert."
                  )}
                </li>
                <li>
                  {__(
                    "No wagering, 50x maximum cash out limitations on converted point."
                  )}
                </li>
                <li>
                  {__(
                    "Mixing Comp Points with other Fund Actions is not allowed."
                  )}
                </li>
                <li>
                  {__(
                    "Comp Points can only be redeemed if balance is less than $1."
                  )}
                </li>
              </ul>
              <div className="links ">
                <Link className="common_btn_v1 fit_width margin_auto">
                  {__("Sign Up")}
                </Link>
              </div>
            </div>
            <div className="item">
              <div className="item_heading">{__("Conditions")}</div>
              <ul>
                <li>{__("You can convert your points (minimum 100).")}</li>
                <li>
                  {__(
                    "You receive up to $/€1 for each 100 points you convert."
                  )}
                </li>
                <li>
                  {__(
                    "No wagering, 50x maximum cash out limitations on converted point."
                  )}
                </li>
                <li>
                  {__(
                    "Mixing Comp Points with other Fund Actions is not allowed."
                  )}
                </li>
                <li>
                  {__(
                    "Comp Points can only be redeemed if balance is less than $1."
                  )}
                </li>
              </ul>
              <div className="links ">
                <Link className="common_btn_v1 fit_width margin_auto">
                  {__("Sign Up")}
                </Link>
              </div>
            </div>
            <div className="item">
              <div className="item_heading">{__("Conditions")}</div>
              <ul>
                <li>{__("You can convert your points (minimum 100).")}</li>
                <li>
                  {__(
                    "You receive up to $/€1 for each 100 points you convert."
                  )}
                </li>
                <li>
                  {__(
                    "No wagering, 50x maximum cash out limitations on converted point."
                  )}
                </li>
                <li>
                  {__(
                    "Mixing Comp Points with other Fund Actions is not allowed."
                  )}
                </li>
                <li>
                  {__(
                    "Comp Points can only be redeemed if balance is less than $1."
                  )}
                </li>
              </ul>
              <div className="links ">
                <Link className="common_btn_v1 fit_width margin_auto">
                  {__("Sign Up")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*Asked by client to remove the block start*/}
        {/* <div className="heading">
          {__("Do you have a VIP Status somewhare else?")}
        </div>
        <div className="title col-lg-8 offset-lg-2">
          {__(
            "If you have VIP status at any of the sites listed below – or any similar sites – let us know your current deal and we will do our best to beat it – we treat our VIPs like they have never been treated before, switch to us and we guarantee you won't be disappointed!"
          )}
        </div> */}

        <div className="rekeback_block">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-2 text-center ">
              <img
                className="margin_top_15"
                src={require("assets/images/v1/vip/rakeback.svg").default}
              />
            </div>
            <div className="col-lg-10">
              <div className="heading text-left">
                {__("Enjoy Instant Rakeback on all your Bets!")}
              </div>
              <div className="title text-left">
                {__(
                  "Don’t want to wait a week to get your rakeback? Never fear, we hear you! At Casino Monte Olympus, if you deposit and lose your deposit, get instant rakeback added to your account from that deposit giving you a second chance to win – why wait for rakeback when you can get it instantly?"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="heading">
          {__("Weekly Reloads Skyrocket your Winning Chances!")}
        </div>
        <div className="title col-lg-8 offset-lg-2">
          {__(
            "On top of our standard weekly promotions, such as Tasty Tuesdays (75% reload bonus) and Free Spins Thursdays (free spins depending on 1st deposit amount of the day), VIPs at Casino Monte Olympus are entitled to bigger and better reloads and much more besides!"
          )}
        </div>

        <div className="slots">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={require("assets/images/v1/vip/slots.svg").default}
                  />
                </div>
                <div className="col-md-8">
                  <div className="heading_colored text-left">
                    {__("Over 5,000 great games to choose from")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={require("assets/images/v1/vip/calender.svg").default}
                  />
                </div>
                <div className="col-md-8">
                  <div className="heading_colored text-left">
                    {__("Weekly and Monthly VIP only promotions and races")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="heading text-left ">
          {__("Blizz Lucky Wheel!")}
        </div>
        <div className="title text-left ">
          {__(
            "A Prize Guaranteed every day you don't win! How's your luck? At Casino Monte Olympus, we want to give you the chance to be lucky, even when you're not! Each day you play and don't win, you will be able to spin the Casino Monte Olympus Cashback Wheel the next day, from 00.00 to 23.59 CET. The amount you turnover determines the tier from which you will spin the wheel, there is a Diamond, Gold, Silver and Bronze one. You will be able to win a % of cashback on your previous day's deposits, from 0.1% up to 10% - On top of the standard 10% weekly cashback every Monday."
          )}
        </div>

        <div className="wheel_table">
          <div className="single_datarow table_head">
            <div className="item text-left">{__("Tier")}</div>
            <div className="item">{__("Diamond")}</div>
            <div className="item">{__("Gold")}</div>
            <div className="item">{__("Silver")}</div>
            <div className="item">{__("Bronze")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Wagering requirements")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Rakeback")}</div>
            <div className="item">{__("10%")}</div>
            <div className="item">{__("10%")}</div>
            <div className="item">{__("10%")}</div>
            <div className="item">{__("10%")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Level up bonus")}</div>
            <div className="item">{__("")}</div>
            <div className="item">{__("")}</div>
            <div className="item">{__("")}</div>
            <div className="item">{__("")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Weekly Bonus")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("yes")}</div>
            <div className="item">{__("yes")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("VIP Host Bonus")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("yes")}</div>
          </div>
        </div>

        <div className="title text-left ">
          {__(
            "All winnings are cash and are based on net loss from the previous days play. Winnings will be added to accounts instantly once the spin has completed.Standard promotional terms apply, Casino Monte Olympus reserves the right to change, modify or remove this promotion at any time without giving a reason."
          )}
        </div>
      </div>
    </div>
  );
}
