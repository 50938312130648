import React, { useState, useEffect } from "react";

const Logo = (props) => {
  return (
    <>
      <img src={require("../../assets/images/v1/site-logo.png").default} />
    </>
  );
};

export default Logo;
