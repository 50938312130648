import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ls from "services/ls";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import swal from "sweetalert";
import Translator, { __ } from "services/Translator";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import contractsAbi from "configs/contract_abi.json";
import Metamask from "services/Metamask";

export default function ConnectWithMetamask(props) {
  const history = useHistory();
  const setSpinner = props.setSpinner;

  const loginWithMetamsak = async () => {
    props.setSpinner(true);
    var response = await Metamask.connectWithMetamask();
    props.setSpinner(false);
    if (response.status === 200) {
      console.log(response);
      props.setMetamaskConnected(true);
      ls.set("metamaskConnected", true);
    } else {
      alert(response.data.message);
    }
  };

  useEffect(() => {
    // loginWithMetamsak();
  }, []);

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="form_area">
              <div className="login_button">
                <Button className="login_btn" onClick={loginWithMetamsak}>
                  <img
                    style={{ width: "23px" }}
                    src={require("assets/images/logos/metamask_fox.png").default}
                    alt={__("Connect with Metamask")}
                  />
                  {__("Connect with Metamask")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
