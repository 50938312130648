import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";

import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";
import { isMobile } from "services/isMobile";

import { __ } from "services/Translator";

import { Accordion, Button, Modal } from "react-bootstrap";

export default function Verify(props) {
  // const __ = (text) => text;

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const { updateUserObj } = React.useContext(AppContext);
  const [poib, setPoib] = useState();
  const [poif, setPoif] = useState();
  const [sof, setSof] = useState();
  const [poa, setPoa] = useState();

  const [level1Verification, setLevel1Verification] = useState(false);

  const [level2submitted, setLevel2submitted] = useState(false);
  const [level2Verification, setLevel2Verification] = useState(false);

  const [level3submitted, setLevel3submitted] = useState(false);
  const [level3Verification, setLevel3Verification] = useState(false);

  const [level4submitted, setLevel4submitted] = useState(false);
  const [level4Verification, setLevel4Verification] = useState(false);
  const getIdUploads = async () => {
    var response = await api.get("/id-uploads");
    console.log("id-uploadsid-uploads", response);
    if (response.status === 200 && response.data) {
      setPoib(response.data.poib);
      setPoif(response.data.poif);
      setPoa(response.data.poa);
      setSof(response.data.sof);

      if (response.data.poif && response.data.poib) {
        if (
          response.data.poif.approved == "approved" &&
          response.data.poib.approved == "approved"
        ) {
          setLevel2Verification(true);
        } else if (
          response.data.poif.approved == "submitted" &&
          response.data.poib.approved == "submitted"
        ) {
          setLevel2submitted(true);
        }
      }

      if (response.data.poa) {
        if (response.data.poa.approved == "approved") {
          setLevel3Verification(true);
        } else if (response.data.poa.approved == "submitted") {
          setLevel3submitted(true);
        }
      }
      if (response.data.sof) {
        if (response.data.sof.approved == "approved") {
          setLevel4Verification(true);
        } else if (response.data.sof.approved == "submitted") {
          setLevel4submitted(true);
        }
      }
    }
  };

  useEffect(async () => {
    getIdUploads();
  }, []);

  const [identityFrontFileName, setIdentityFrontFileName] = useState();
  const [identityFrontFile, setIdentityFrontFile] = useState();
  // console.log(identityFrontFile);
  const pickIdentityFront = (event) => {
    setIdentityFrontFileName(event.target.files[0].name);
    setIdentityFrontFile(event.target.files[0]);
  };

  const [identityBackFileName, setIdentityBackFileName] = useState();
  const [identityBackFile, setIdentityBackFile] = useState();
  // console.log(identityBackFile);
  const pickIdentityBack = (event) => {
    setIdentityBackFileName(event.target.files[0].name);
    setIdentityBackFile(event.target.files[0]);
  };

  const [uploadIFRes, setUploadIFRes] = useState(false);
  const uploadIdentityFrontFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "poif");
    formData.append("file", identityFrontFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadIFRes(true);
      setIdentityFrontFile("");
    } else {
      props.showAlert(__("Error!!"), response.data.message, true);

      setUploadIFRes(false);
    }
  };

  const [uploadIBRes, setUploadIBRes] = useState(false);
  const uploadIdentityBackFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "poib");
    formData.append("file", identityBackFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadIBRes(true);
      setIdentityBackFileName("");
    } else {
      props.showAlert(__("Error!!"), response.data.message, true);
      setUploadIBRes(false);
    }
  };

  const uploadProofOfIdentity = () => {
    var valid = validateProofOfIdentity();
    if (valid) {
      if (level1Verification) {
        uploadIdentityFrontFile();
        uploadIdentityBackFile();
      } else {
        props.showAlert(
          __("Warning!!"),
          __("Need to verify level 1 before uploading level 2."),
          true
        );
      }
    } else {
      props.showAlert(
        __("Error!!"),
        __("Proof Of Identity both field are required"),
        true
      );
    }
  };

  const validateProofOfIdentity = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (identityFrontFile == undefined) {
      valid = false;
    }

    if (identityBackFile == undefined) {
      valid = false;
    }
    return valid;
  };

  useEffect(async () => {
    if (uploadIBRes && uploadIFRes) {
      props.showAlert(
        __("Success!!"),
        __("Proof Of Identity Successfully Uploaded!")
      );
      setUploadIBRes(false);
      setUploadIFRes(false);
    }
  }, [uploadIBRes, uploadIFRes]);

  const [fundFileName, setFundFileName] = useState();
  const [fundFile, setFundFile] = useState();
  const pickFundFile = (event) => {
    setFundFileName(event.target.files[0].name);
    setFundFile(event.target.files[0]);
  };

  const [addressFileName, setAddressFileName] = useState();
  const [addressFile, setAddressFile] = useState();
  const pickAddressFile = (event) => {
    setAddressFileName(event.target.files[0].name);
    setAddressFile(event.target.files[0]);
  };

  const [uploadFFRes, setUploadFFRes] = useState(false);
  const uploadFundFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "sof");
    formData.append("file", fundFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadFFRes(true);
      setFundFile("");
    } else {
      props.showAlert(__("Error!!"), response.data.message, true);
      setUploadFFRes(false);
    }
  };

  const [uploadAFRes, setUploadAFRes] = useState(false);
  const uploadAddressFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "poa");
    formData.append("file", addressFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadAFRes(true);
      setAddressFile("");
    } else {
      props.showAlert(__("Error!!"), response.data.message, true);
      setUploadAFRes(false);
    }
  };

  const uploadLevelThreeDoc = () => {
    var valid = validateLevelThreeDoc();
    if (valid) {
      if (level2Verification) {
        uploadAddressFile();
      } else {
        props.showAlert(
          __("Warning!!"),
          __("Need to verify level 2 before uploading level 3."),
          true
        );
      }
    } else {
      props.showAlert(
        __("Warning!!"),
        __("Proof Of Address field is required"),
        true
      );
    }
    window.scrollTo(0, 0);
  };

  const uploadLevelFourDoc = () => {
    var valid = validateLevelFourDoc();
    if (valid) {
      if (level3Verification) {
        uploadFundFile();
      } else {
        props.showAlert(
          __("Warning!!"),
          __("Need to verify level 3 before uploading level 4."),
          true
        );
      }
    } else {
      props.showAlert(
        __("Warning!!"),
        __("Source Of Funds field is required"),
        true
      );
    }
    // window.scrollTo(0, 0);
  };

  const validateLevelThreeDoc = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (addressFile == undefined) {
      valid = false;
    }
    return valid;
  };

  const validateLevelFourDoc = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (fundFile == undefined) {
      valid = false;
    }

    return valid;
  };

  useEffect(async () => {
    if (uploadAFRes) {
      props.showAlert(
        __("Success"),
        __("Proof Of Address Successfully Uploaded")
      );
      setUploadAFRes(false);
    }
  }, [uploadAFRes]);

  useEffect(async () => {
    if (uploadFFRes) {
      props.showAlert(
        __("Success"),
        __("Source Of Funds Successfully Uploaded")
      );
      setUploadFFRes(false);
    }
  }, [uploadFFRes]);

  //Level 1 functionality START'S here
  const [basicProfileErrors, setBasicProfileErrors] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    address: "",
    dateOfBirth: "",
    city: "",
    zip: "",
    occupation: "",
  });
  const [basicProfileData, setBasicProfileData] = useState({
    firstName: "",
    lastName: "",
    bday: 1,
    bmonth: 1,
    byear: 2003,
    playerCountry: "",
    address: "",
    city: "",
    zip: "",
    occupation: "",
    phoneCode: "",
    phone: "",
  });

  const updateLevelOneInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    setBasicProfileData({ ...basicProfileData, [field]: value });
  };

  // const [mainProfileData, setMainProfileData] = useState();
  const [mainProfileData, setMainProfileData] = useState({
    firstName: "",
    lastName: "",
    bday: 1,
    bmonth: 1,
    byear: 2003,
    playerCountry: "",
    address: "",
    city: "",
    zip: "",
    occupation: "",
    phoneCode: "",
    phone: "",
  });
  const getProfile = async () => {
    var response = await api.get("/profile");
    if (response.status === 200 && response.data) {
      setBasicProfileData(response.data);
      setMainProfileData(response.data);
      if (
        response.data.firstName &&
        response.data.lastName &&
        response.data.bday &&
        response.data.bmonth &&
        response.data.byear &&
        response.data.playerCountry &&
        response.data.phone &&
        response.data.address &&
        response.data.city &&
        response.data.zip
      ) {
        setLevel1Verification(true);
      }
    }
  };

  const saveBasicProfileData = async (ev) => {
    setBasicProfileErrors({
      firstName: "",
      lastName: "",
      nickName: "",
      address: "",
      dateOfBirth: "",
      city: "",
      zip: "",
      occupation: "",
      phoneCode: "",
      phone: "",
    });
    var response = await api.post("/profile/save-basic", basicProfileData);
    console.log(response);
    if (response.status == 200) {
      props.showAlert(__("Success"), response.data.message);
      getProfile();
    } else {
      if (response.data.errors) {
        setBasicProfileErrors(response.data.errors);
      } else {
        props.showAlert(__("Error!!"), response.data.message, true);
      }
    }
  };

  useEffect(async () => {
    getProfile();
  }, []);

  //Level 1 functionality END'S here

  const [allCountries, setAllCountries] = useState([]);
  const getAllCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setAllCountries(response.data);
    }
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
    getAllCountries();
  }, []);

  const [perviewImageModal, setPerviewImageModal] = useState(false);
  const [perviewImage, setPerviewImage] = useState([]);
  const showBigImage = (img) => {
    setPerviewImage(img);
    setPerviewImageModal(true);
  };
  const closePerviewImageModal = () => {
    setPerviewImageModal(false);
  };

  return (
    <>
      <div className="faq_container">
        <div className="faq_bottom_area">
          <Accordion
            className="chimp-accordions verify_area access_control_area"
            alwaysOpen
          >
            <Accordion.Item className="chimp-accordion" eventKey="0">
              <Accordion.Header>
                {__("Level 1")}
                &nbsp;{" "}
                {level1Verification ? (
                  <i class="fas fa-check"></i>
                ) : (
                  <i class={"fas fa-exclamation "}></i>
                )}
              </Accordion.Header>
              <Accordion.Body className="myaccount_input_area">
                <p>
                  {__(
                    "Once you have completed all mandatory fields, your data is locked, and only our Support Team can change data when needed. For that you need to contact us."
                  )}
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("First Name")}
                            <span className="requried_icon"> *</span>
                          </div>

                          <input
                            type="text"
                            name="firstName"
                            placeholder={__("First Name")}
                            disabled={mainProfileData.firstName ? true : false}
                            value={basicProfileData.firstName}
                            onChange={updateLevelOneInput}
                            className={
                              "form-control name_value " +
                              (basicProfileErrors.firstName ? "error" : "")
                            }
                          />
                          <div class="errorMsg">
                            {basicProfileErrors.firstName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Last Name")}
                            <span className="requried_icon"> *</span>
                          </div>
                          <input
                            type="text"
                            name="lastName"
                            disabled={mainProfileData.lastName ? true : false}
                            value={basicProfileData.lastName}
                            onChange={updateLevelOneInput}
                            placeholder={__("Last Name")}
                            className={
                              "form-control name_value " +
                              (basicProfileErrors.lastName ? "error" : "")
                            }
                          />
                          <div class="errorMsg">
                            {basicProfileErrors.lastName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("Date of Birth")}
                            <span className="requried_icon"> *</span>
                          </div>

                          <input
                            autocomplete="off"
                            className="form-control name_value"
                            type="number"
                            name="bday"
                            disabled={mainProfileData.bday ? true : false}
                            value={basicProfileData.bday}
                            onChange={updateLevelOneInput}
                            step="1"
                            min="1"
                            placeholder={__("Day")}
                            max="31"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">&nbsp;</div>
                          <input
                            autocomplete="off"
                            className="form-control name_value"
                            type="number"
                            name="bmonth"
                            disabled={mainProfileData.bmonth ? true : false}
                            value={basicProfileData.bmonth}
                            onChange={updateLevelOneInput}
                            step="1"
                            min="1"
                            max="12"
                            placeholder={__("Month")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">&nbsp;</div>
                          <input
                            autocomplete="off"
                            className="form-control name_value"
                            type="number"
                            name="byear"
                            disabled={mainProfileData.byear ? true : false}
                            value={basicProfileData.byear}
                            onChange={updateLevelOneInput}
                            step="1"
                            min="1900"
                            placeholder={__("Year")}
                          />
                        </div>
                      </div>
                      <div class="errorMsg">
                        {basicProfileErrors.dateOfBirth}
                      </div>
                    </div>
                    <div className="row">
                      <div className="input_item">
                        <div className="name">
                          {__("Country")}
                          <span className="requried_icon"> *</span>
                        </div>
                        <select
                          name="playerCountry"
                          value={basicProfileData.playerCountry}
                          onChange={updateLevelOneInput}
                          className="form-control name_value"
                          disabled={
                            mainProfileData.playerCountry ? true : false
                          }
                        >
                          <option>{__("Select Country")}</option>
                          {allCountries.map((item, index) => (
                            <option value={item.code}>{item.nicename}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Area Code")}{" "}
                            <span className="requried_icon"> *</span>
                          </div>

                          <select
                            name="playerCountry"
                            value={basicProfileData.phoneCode}
                            onChange={updateLevelOneInput}
                            className="form-control name_value"
                            disabled={mainProfileData.phone ? true : false}
                          >
                            <option>{__("Select Area Code")}</option>
                            {allCountries.map((item, index) => (
                              <option value={"+" + item.phonecode}>
                                +{item.phonecode} - {item.nicename}
                              </option>
                            ))}
                          </select>
                          <div class="errorMsg">
                            {basicProfileErrors.phoneCode}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Phone")}{" "}
                            <span className="requried_icon"> *</span>
                          </div>

                          <input
                            type="number"
                            name="phone"
                            value={basicProfileData.phone}
                            onChange={updateLevelOneInput}
                            disabled={mainProfileData.phone ? true : false}
                            className={
                              "form-control name_value " +
                              (basicProfileErrors.phone ? "error" : "")
                            }
                            placeholder={__("Phone Number")}
                          />
                          <div class="errorMsg">{basicProfileErrors.phone}</div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="input_item">
                        <div className="name">
                          {__("Residential Address")}{" "}
                          <span className="requried_icon"> *</span>
                        </div>
                        <input
                          type="text"
                          name="address"
                          value={basicProfileData.address}
                          disabled={mainProfileData.address ? true : false}
                          placeholder={__("Residential Address")}
                          onChange={updateLevelOneInput}
                          className={
                            "form-control name_value " +
                            (basicProfileErrors.address ? "error" : "")
                          }
                        />
                        <div class="errorMsg">{basicProfileErrors.address}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("City")}{" "}
                            <span className="requried_icon"> *</span>
                          </div>
                          <input
                            type="text"
                            name="city"
                            value={basicProfileData.city}
                            disabled={mainProfileData.city ? true : false}
                            onChange={updateLevelOneInput}
                            placeholder={__("City")}
                            className={
                              "form-control name_value " +
                              (basicProfileErrors.city ? "error" : "")
                            }
                          />
                          <div class="errorMsg">{basicProfileErrors.city}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Postal Code")}{" "}
                            <span className="requried_icon"> *</span>
                          </div>

                          <input
                            type="text"
                            name="zip"
                            value={basicProfileData.zip}
                            onChange={updateLevelOneInput}
                            disabled={mainProfileData.zip ? true : false}
                            className={
                              "form-control name_value " +
                              (basicProfileErrors.zip ? "error" : "")
                            }
                            placeholder={__("Postal Code")}
                          />
                          <div class="errorMsg">{basicProfileErrors.zip}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="input_item">
                        <div className="name">
                          {__("Occupation")} ({__("Your job/work")})
                          {/* <span className="requried_icon"> *</span> */}
                        </div>
                        <input
                          className="form-control name_value"
                          type="text"
                          name="occupation"
                          disabled={mainProfileData.occupation ? true : false}
                          value={basicProfileData.occupation}
                          onChange={updateLevelOneInput}
                          placeholder={__("Occupation")}
                        />
                      </div>
                    </div>

                    <div className="justify-content-center">
                      <div>
                        {__("All data is safely stored and encrypted.")}
                      </div>
                      <br />
                      <button
                        onClick={saveBasicProfileData}
                        className="common_btn_v1 fit_width"
                      >
                        {__("Submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="chimp-accordion" eventKey="1">
              <Accordion.Header>
                {__("Level 2")} &nbsp;{" "}
                {level2Verification ? (
                  <i class="fas fa-check"></i>
                ) : (
                  <i
                    class={
                      "fas fa-exclamation " +
                      (level2submitted ? "submitted" : "")
                    }
                  ></i>
                )}
              </Accordion.Header>
              <Accordion.Body className="myaccount_input_area">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form_area">
                      <div className="input_item">
                        <div className="name">
                          {__(
                            "Please upload your Proof of Identity. All documents must be lying on a flat surface with all 4 corners inside the frame, and all information should be clear and identifiable. While photographs of ID cards and Passport are acceptable, scanned documents are not."
                          )}
                        </div>
                        <div className="name description">
                          {__(
                            "Following file types are accepted: png, jpg, .pdf"
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input_item">
                            <div className="name">
                              {__("Proof of Identity (Front)")}
                              <span className="requried_icon"> *</span>
                            </div>
                            <div className="form-control name_value overflow-hidden">
                              {identityFrontFileName}
                            </div>
                            <input
                              onChange={pickIdentityFront}
                              id="identity_front"
                              type="file"
                              hidden
                              accept=".jpg, .jpeg, .png, .pdf"
                              name="identity_front"
                              className="form-control input_box"
                            />
                            <label className="d-block" for="identity_front">
                              {!!identityFrontFileName || (
                                <i className="fal fa-file-upload in_input_icon"></i>
                              )}
                              {!!identityFrontFileName && (
                                <i className="fad fa-check-square in_input_icon upload_success"></i>
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input_item">
                            <div className="name">
                              {__("Proof of Identity (Back)")}
                              <span className="requried_icon"> *</span>
                            </div>
                            <div className="form-control name_value overflow-hidden">
                              {identityBackFileName}
                            </div>
                            <input
                              onChange={pickIdentityBack}
                              id="identity_back"
                              type="file"
                              hidden
                              accept=".jpg, .jpeg, .png, .pdf"
                              name="identity_back"
                              className="form-control name_value"
                            />
                            <label className="d-block" for="identity_back">
                              {!!identityBackFileName || (
                                <i className="fal fa-file-upload in_input_icon"></i>
                              )}
                              {!!identityBackFileName && (
                                <i class="fad fa-check-square in_input_icon upload_success"></i>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>

                      {!level2Verification && (
                        <div className="login_button">
                          <button
                            onClick={uploadProofOfIdentity}
                            className="common_btn_v1 fit_width "
                          >
                            {__("Upload")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ul className="proof_view_area">
                      {poif && (
                        <li>
                          <img
                            onClick={() => showBigImage(poif.api_photo_url)}
                            src={poif.api_photo_url}
                          />
                        </li>
                      )}
                      {poib && (
                        <li>
                          <img
                            onClick={() => showBigImage(poib.api_photo_url)}
                            src={poib.api_photo_url}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="chimp-accordion" eventKey="2">
              <Accordion.Header>
                {__("Level 3")} &nbsp;{" "}
                {level3Verification ? (
                  <i class="fas fa-check"></i>
                ) : (
                  <i
                    class={
                      "fas fa-exclamation " +
                      (level3submitted ? "submitted" : "")
                    }
                  ></i>
                )}
              </Accordion.Header>

              <Accordion.Body className="myaccount_input_area">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form_area">
                      <div className="input_item">
                        <div className="name">
                          {__(
                            "Please upload supporting documentation for your Proof of Address. Documents lying a on a flat surface must show all 4 corners and all information should be clear and identifiable."
                          )}
                        </div>
                        <div className="name description">
                          {__("Following file types are accepted")}: png, jpg,
                          .pdf
                        </div>
                      </div>
                      <div className="input_item">
                        <div className="name">
                          {__("Proof of Address")}
                          <span className="requried_icon"> *</span>
                        </div>
                        <div className="form-control name_value overflow-hidden">
                          {addressFileName}
                        </div>
                        <input
                          onChange={pickAddressFile}
                          id="address_file"
                          type="file"
                          hidden
                          name="address_file"
                          className="form-control name_value"
                        />
                        <label className="d-block" for="address_file">
                          {!!addressFileName || (
                            <i className="fal fa-file-upload in_input_icon"></i>
                          )}
                          {!!addressFileName && (
                            <i class="fad fa-check-square in_input_icon upload_success"></i>
                          )}
                        </label>
                      </div>

                      {!level3Verification && (
                        <div className="login_button">
                          <button
                            onClick={uploadLevelThreeDoc}
                            className="common_btn_v1 fit_width "
                          >
                            {__("Upload")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ul className="proof_view_area">
                      {poa && (
                        <li>
                          <img
                            onClick={() => showBigImage(poa.api_photo_url)}
                            src={poa.api_photo_url}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="chimp-accordion" eventKey="3">
              <Accordion.Header>
                {__("Level 4")} &nbsp;{" "}
                {level4Verification ? (
                  <i class="fas fa-check"></i>
                ) : (
                  <i
                    class={
                      "fas fa-exclamation " +
                      (level4submitted ? "submitted" : "")
                    }
                  ></i>
                )}
              </Accordion.Header>

              <Accordion.Body className="myaccount_input_area">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form_area">
                      <div className="input_item">
                        <div className="name">
                          {__(
                            "Please upload supporting documentation for your Source of Funds. Documents lying a on a flat surface must show all 4 corners and all information should be clear and identifiable."
                          )}
                        </div>
                        <div className="name description">
                          {__("Following file types are accepted")}: png, jpg,
                          .pdf
                        </div>
                      </div>
                      <div className="input_item">
                        <div className="name">
                          {__("Source of Funds")}
                          <span className="requried_icon"> *</span>
                        </div>
                        <div className="form-control name_value overflow-hidden">
                          {fundFileName}
                        </div>
                        <input
                          onChange={pickFundFile}
                          id="fund_file"
                          type="file"
                          hidden
                          name="fund_file"
                          className="form-control name_value"
                        />
                        <label className="d-block" for="fund_file">
                          {!!fundFileName || (
                            <i className="fal fa-file-upload in_input_icon"></i>
                          )}
                          {!!fundFileName && (
                            <i class="fad fa-check-square in_input_icon upload_success"></i>
                          )}
                        </label>
                      </div>

                      {!level4Verification && (
                        <div className="login_button">
                          <button
                            onClick={uploadLevelFourDoc}
                            className="common_btn_v1 fit_width "
                          >
                            {__("Upload")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ul className="proof_view_area">
                      {sof && (
                        <li>
                          <img
                            onClick={() => showBigImage(sof.api_photo_url)}
                            src={sof.api_photo_url}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <Modal
        show={perviewImageModal}
        onHide={closePerviewImageModal}
        size={"lg"}
      >
        <Modal.Body className="login_modal forgot_pass">
          <div onClick={closePerviewImageModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>
          <img alt="" style={{ width: "100%" }} src={perviewImage} />
        </Modal.Body>
      </Modal>
    </>
  );
}
