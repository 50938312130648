import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SelectBox from "elements/SelectBox";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import UserLeftArea from "elements/dashboard_elements/UserLeftArea";
import { isMobile } from "services/isMobile";
import Translator, { __ } from "services/Translator";
import { Modal } from "react-bootstrap";

import PageHeadline from "../PageHeadline";

import FiatCashier from "elements/dashboard_elements/FiatCashier";

export default function Cashier(props) {
  // const __ = (text) => text;
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [selectedWallet, setSelectedWalelt] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  const [deleteBonusModalOpen, setDeleteBonusModalOpen] = useState(false);

  const doDeleteBonuses = async () => {
    props.setSpinner(true);
    var response = await api.post("/delete-bonus");
    if (response.status === 200) {
      props.setSpinner(false);
      toggleDeleteBonusModalOpen();
      props.getUserBalance();
      props.showAlert("Warning!!", response.data.message);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const toggleDeleteBonusModalOpen = () => {
    setDeleteBonusModalOpen(!deleteBonusModalOpen);
  };

  useEffect(() => {
    if (props.userBalance.wallets.length) {
      setSelectedWalelt(props.userBalance.wallets[0].currency);
      setSelectItems(
        props.userBalance.wallets.map((item) => {
          return { label: item.currency, value: item.value };
        })
      );
    }
  }, [props.userBalance]);

  const today =
    new Date().getFullYear() +
    "-" +
    new Date().getMonth() +
    "-" +
    new Date().getDate();

  return (
    <>
      <PageHeadline page_name="Cashier" />
      <div className="page_content_area">
        <div className="progress_chart_area">
          <div className="progress_content">
            <div className="content_title">
              {__("Balance")}
              <img
                src={
                  require("assets/images/icons/mastercard.png").default
                }
                alt={__("Mastercard Icon")}
              />
            </div>
            <div className="content_details">
              <div className="text_section">
                <h3 className="balance">
                  {"$" + props.userBalance.total_balance}
                </h3>
                <div className="date_details">
                  <div className="paragraph no_margin white">
                    {__("Current Date")}
                  </div>
                  <div className="date_value">{today}</div>
                </div>
              </div>
              <div className="progress_section">
                <CircularProgressbarWithChildren
                  className="progress_container"
                  value={props.userBalance.total_balance}
                  counterClockwise={true}
                >
                  <div className="progress_value">
                    {"$" + props.userBalance.total_balance}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
          <div className="progress_content chart_area">
            <div className="content_title">{__("Cash Balance")}</div>
            <div className="content_details">
              <div className="text_section">
                <h3 className="balance">
                  {"$" + props.userBalance.cash_balance}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="bonus_intro">
          <div className="intro_title">
            {__("Bonus Balance")}: {"$" + props.userBalance.bonus_balance}
          </div>
          <div className="intro_title">
            {__("Bonus Unlock Progress")}: (
            {"$" + props.userBalance.wagering_requirements_achieved}/
            {"$" + props.userBalance.wagering_requirements_total})
          </div>
          <Link
            hrefLang={Translator.getSelectedLanguage().code}
            to="#"
            onClick={toggleDeleteBonusModalOpen}
            className="common_btn_v1 red "
          >
            {__("Delete Bonus")}
          </Link>
        </div>

        <Modal show={deleteBonusModalOpen} onHide={toggleDeleteBonusModalOpen}>
          <Modal.Body className={"login_modal " + props.theme}>
            <div onClick={toggleDeleteBonusModalOpen} className="modal_close">
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="#666666"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 20L19.3848 1.61522"
                  stroke="#666666"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M1 2L19.3848 20.3848"
                  stroke="#666666"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>

            <div className="modal_headline">
              {__("Are you sure to delete your bonus balance?")}
            </div>

            <div className="row">
              <div className="col-6 col-md-4 offset-md-2 col-lg-3 offset-lg-3">
                <button
                  type="button"
                  className="common_btn_v1"
                  onClick={toggleDeleteBonusModalOpen}
                >
                  {__("No")}
                </button>
              </div>
              <div className="col-6 col-md-4 col-lg-3">
                <button
                  type="button"
                  className="common_btn_v1 red"
                  onClick={doDeleteBonuses}
                >
                  {__("Yes")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <br />
        <FiatCashier {...props} />
      </div>
    </>
  );
}
