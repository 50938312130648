import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useHistory, useParams } from "react-router-dom";

import Translator, { __ } from "services/Translator";
import NoRecordsFound from "elements/NoRecordsFound";

export default function Landing(props) {
  useEffect(async () => {
    
  }, []);

  return (
    <div className="landing">
      <NoRecordsFound
        content={__(
          "The page you are looking for does not exist. Please click on the logo on top-right corner to go to the homepage."
        )}
      />
    </div>
  );
}
