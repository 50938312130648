import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function VipBanner(props) {
  return (
    <div className="">
      <div className="container">
        <PageHeadline page_name="VIP" />
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="single_item blue vip">
              <div className="icon">
                <img
                  src={
                    require("assets/images/v1/banner/vip/vip-banner.svg")
                      .default
                  }
                />
              </div>
              <div className="description">
                <div className="heading">
                  {__("Monte Olympus VIP program!")}
                </div>
                <div className="desc">
                  {__(
                    "At Casino Monte Olympus, we celebrate loyalty by giving incredible rewards – brace yourself for a bonanza of promotions, cashback, surprises, and so much more! The more you immerse yourself in our gaming paradise, the greater the returns – that's a guarantee!"
                  )}
                </div>
                <div className="links">
                  <Link
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn_v1 fit_width uppercase"
                  >
                    {__("start now!")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
