import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

export default function PromotionCashback() {
  return (
    <div className="container">
      <div className="promotion_cashback">
        <div className="row">
          <div className="col-lg-6">
            <div className="single_cashback">
              
              <div className="cashback_description">
                <div className="heading">{__("Weekly Cashback")}</div>
                <div className="title">{__("10% Cashback")}</div>
                <div className="desc truncunate">
                  {__(
                    "We like to take care of our players here at Blizz Casino, that's why we have so manygreat games to choose from... Read more"
                  )}
                </div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                  <Link className="common_btn_v1 deep fit_width">
                    {__("Terms")}
                  </Link>
                </div>
              </div>
              <div className="cashback_icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/5.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_cashback">
              
              <div className="cashback_description">
                <div className="heading">
                  {" "}
                  {__("1")}
                  <sup>{__("st")}</sup> {__("Deposit")}
                </div>
                <div className="title">{__("100% up to 1 BTC!")}</div>
                <div className="desc truncunate">
                  {__(
                    "At Genie Jackpot, we welcome all new players and want them to feel at home. For this reason, we are giving all"
                  )}
                </div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                  <Link className="common_btn_v1 deep fit_width">
                    {__("Terms")}
                  </Link>
                </div>
              </div>
              <div className="cashback_icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/2.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_cashback">
              
              <div className="cashback_description">
                <div className="heading">
                  {" "}
                  {__("2")}
                  <sup>{__("nd")}</sup> {__("Deposit")}
                </div>
                <div className="title">{__("50% up to 1.2BTC")}</div>
                <div className="desc truncunate">
                  {__(
                    "For your second deposit, the Genie Jackpot team will give you a 50% Bonus of up to 1.2BTC."
                  )}
                </div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                  <Link className="common_btn_v1 deep fit_width">
                    {__("Terms")}
                  </Link>
                </div>
              </div>
              <div className="cashback_icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/4.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_cashback">
              
              <div className="cashback_description">
                <div className="heading">{__("Tasty Tuesday")}</div>
                <div className="title">{__("75% Bonus on Deposit")}</div>
                <div className="desc truncunate">
                  {__(
                    "Tuesday. Not the most exciting day of theweek. Only 2 days into the week itself, theweekend still further away tha"
                  )}
                </div>

                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                  <Link className="common_btn_v1 deep fit_width">
                    {__("Terms")}
                  </Link>
                </div>
              </div>
              <div className="cashback_icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/1.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
