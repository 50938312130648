import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";
import PromotionsBanner from "elements/promotion/PromotionsBanner";
import PromotionCashback from "elements/promotion/PromotionCashback";
import PromotionFreespins from "elements/promotion/PromotionFreespins";
import PageHeadline from "../elements/PageHeadline";

export default function Promotions(props) {
  return (
    <div className="landing">
      {/* <PageHeadline page_name={__("Promotions")} /> */}
      <PromotionsBanner />
      <PromotionCashback />
      <PromotionFreespins />
    </div>
  );
}
