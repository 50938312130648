import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HomeSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    // initialSlide: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1.3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home_slider_v1">
      <div className="container">
        <div className="home_slider_heading">
          <div className="headline">{__("Hot games")}</div>
          <div className="links">
            <Link>
              {__("See all")}{" "}
              <span className="icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#1F2933"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
            </Link>
          </div>
        </div>
        <div className="home_slider_carousel">
          <Slider {...settings}>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-1.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Diamonds ")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2451_16790)">
                    <path
                      d="M5 2V31L17.5 20.7584L30 31V2H5Z"
                      fill="url(#paint0_linear_2451_16790)"
                    />
                  </g>
                  <path
                    d="M13.9395 17H12.6695V14.17H9.89945V17H8.63945V10.26H9.89945V13.08H12.6695V10.26H13.9395V17ZM21.4387 13.61C21.4387 14.65 21.1487 15.4867 20.5687 16.12C19.9953 16.7533 19.2253 17.07 18.2587 17.07C17.292 17.07 16.5187 16.7533 15.9387 16.12C15.3587 15.4867 15.0687 14.65 15.0687 13.61C15.0687 12.5633 15.3553 11.7233 15.9287 11.09C16.5087 10.4567 17.282 10.14 18.2487 10.14C19.2287 10.14 20.0053 10.4567 20.5787 11.09C21.152 11.7167 21.4387 12.5567 21.4387 13.61ZM16.4187 13.61C16.4187 14.3167 16.5853 14.8767 16.9187 15.29C17.252 15.7033 17.6987 15.91 18.2587 15.91C18.8253 15.91 19.272 15.7067 19.5987 15.3C19.9253 14.8867 20.0887 14.3233 20.0887 13.61C20.0887 12.8967 19.9253 12.3333 19.5987 11.92C19.272 11.5067 18.822 11.3 18.2487 11.3C17.682 11.3 17.2353 11.5067 16.9087 11.92C16.582 12.3333 16.4187 12.8967 16.4187 13.61ZM26.8152 11.3H25.1052V17H23.8352V11.3H22.1252V10.26H26.8152V11.3Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_2451_16790"
                      x="1"
                      y="0"
                      width="33"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2451_16790"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2451_16790"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2451_16790"
                      x1="17.542"
                      y1="30.984"
                      x2="17.542"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1C75BC" />
                      <stop offset="0.5062" stop-color="#46C8F5" />
                      <stop offset="1" stop-color="#1C75BC" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-2.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__(" All Mine Diamonds ")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2451_16790)">
                    <path
                      d="M5 2V31L17.5 20.7584L30 31V2H5Z"
                      fill="url(#paint0_linear_2451_16790)"
                    />
                  </g>
                  <path
                    d="M13.9395 17H12.6695V14.17H9.89945V17H8.63945V10.26H9.89945V13.08H12.6695V10.26H13.9395V17ZM21.4387 13.61C21.4387 14.65 21.1487 15.4867 20.5687 16.12C19.9953 16.7533 19.2253 17.07 18.2587 17.07C17.292 17.07 16.5187 16.7533 15.9387 16.12C15.3587 15.4867 15.0687 14.65 15.0687 13.61C15.0687 12.5633 15.3553 11.7233 15.9287 11.09C16.5087 10.4567 17.282 10.14 18.2487 10.14C19.2287 10.14 20.0053 10.4567 20.5787 11.09C21.152 11.7167 21.4387 12.5567 21.4387 13.61ZM16.4187 13.61C16.4187 14.3167 16.5853 14.8767 16.9187 15.29C17.252 15.7033 17.6987 15.91 18.2587 15.91C18.8253 15.91 19.272 15.7067 19.5987 15.3C19.9253 14.8867 20.0887 14.3233 20.0887 13.61C20.0887 12.8967 19.9253 12.3333 19.5987 11.92C19.272 11.5067 18.822 11.3 18.2487 11.3C17.682 11.3 17.2353 11.5067 16.9087 11.92C16.582 12.3333 16.4187 12.8967 16.4187 13.61ZM26.8152 11.3H25.1052V17H23.8352V11.3H22.1252V10.26H26.8152V11.3Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_2451_16790"
                      x="1"
                      y="0"
                      width="33"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2451_16790"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2451_16790"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2451_16790"
                      x1="17.542"
                      y1="30.984"
                      x2="17.542"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1C75BC" />
                      <stop offset="0.5062" stop-color="#46C8F5" />
                      <stop offset="1" stop-color="#1C75BC" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-3.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__(" All Mine Diamonds ")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2451_16790)">
                    <path
                      d="M5 2V31L17.5 20.7584L30 31V2H5Z"
                      fill="url(#paint0_linear_2451_16790)"
                    />
                  </g>
                  <path
                    d="M13.9395 17H12.6695V14.17H9.89945V17H8.63945V10.26H9.89945V13.08H12.6695V10.26H13.9395V17ZM21.4387 13.61C21.4387 14.65 21.1487 15.4867 20.5687 16.12C19.9953 16.7533 19.2253 17.07 18.2587 17.07C17.292 17.07 16.5187 16.7533 15.9387 16.12C15.3587 15.4867 15.0687 14.65 15.0687 13.61C15.0687 12.5633 15.3553 11.7233 15.9287 11.09C16.5087 10.4567 17.282 10.14 18.2487 10.14C19.2287 10.14 20.0053 10.4567 20.5787 11.09C21.152 11.7167 21.4387 12.5567 21.4387 13.61ZM16.4187 13.61C16.4187 14.3167 16.5853 14.8767 16.9187 15.29C17.252 15.7033 17.6987 15.91 18.2587 15.91C18.8253 15.91 19.272 15.7067 19.5987 15.3C19.9253 14.8867 20.0887 14.3233 20.0887 13.61C20.0887 12.8967 19.9253 12.3333 19.5987 11.92C19.272 11.5067 18.822 11.3 18.2487 11.3C17.682 11.3 17.2353 11.5067 16.9087 11.92C16.582 12.3333 16.4187 12.8967 16.4187 13.61ZM26.8152 11.3H25.1052V17H23.8352V11.3H22.1252V10.26H26.8152V11.3Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_2451_16790"
                      x="1"
                      y="0"
                      width="33"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2451_16790"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2451_16790"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2451_16790"
                      x1="17.542"
                      y1="30.984"
                      x2="17.542"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1C75BC" />
                      <stop offset="0.5062" stop-color="#46C8F5" />
                      <stop offset="1" stop-color="#1C75BC" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-4.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__(" All Mine Diamonds ")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2451_16790)">
                    <path
                      d="M5 2V31L17.5 20.7584L30 31V2H5Z"
                      fill="url(#paint0_linear_2451_16790)"
                    />
                  </g>
                  <path
                    d="M13.9395 17H12.6695V14.17H9.89945V17H8.63945V10.26H9.89945V13.08H12.6695V10.26H13.9395V17ZM21.4387 13.61C21.4387 14.65 21.1487 15.4867 20.5687 16.12C19.9953 16.7533 19.2253 17.07 18.2587 17.07C17.292 17.07 16.5187 16.7533 15.9387 16.12C15.3587 15.4867 15.0687 14.65 15.0687 13.61C15.0687 12.5633 15.3553 11.7233 15.9287 11.09C16.5087 10.4567 17.282 10.14 18.2487 10.14C19.2287 10.14 20.0053 10.4567 20.5787 11.09C21.152 11.7167 21.4387 12.5567 21.4387 13.61ZM16.4187 13.61C16.4187 14.3167 16.5853 14.8767 16.9187 15.29C17.252 15.7033 17.6987 15.91 18.2587 15.91C18.8253 15.91 19.272 15.7067 19.5987 15.3C19.9253 14.8867 20.0887 14.3233 20.0887 13.61C20.0887 12.8967 19.9253 12.3333 19.5987 11.92C19.272 11.5067 18.822 11.3 18.2487 11.3C17.682 11.3 17.2353 11.5067 16.9087 11.92C16.582 12.3333 16.4187 12.8967 16.4187 13.61ZM26.8152 11.3H25.1052V17H23.8352V11.3H22.1252V10.26H26.8152V11.3Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_2451_16790"
                      x="1"
                      y="0"
                      width="33"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2451_16790"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2451_16790"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2451_16790"
                      x1="17.542"
                      y1="30.984"
                      x2="17.542"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1C75BC" />
                      <stop offset="0.5062" stop-color="#46C8F5" />
                      <stop offset="1" stop-color="#1C75BC" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-5.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__(" All Mine Diamonds ")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2451_16790)">
                    <path
                      d="M5 2V31L17.5 20.7584L30 31V2H5Z"
                      fill="url(#paint0_linear_2451_16790)"
                    />
                  </g>
                  <path
                    d="M13.9395 17H12.6695V14.17H9.89945V17H8.63945V10.26H9.89945V13.08H12.6695V10.26H13.9395V17ZM21.4387 13.61C21.4387 14.65 21.1487 15.4867 20.5687 16.12C19.9953 16.7533 19.2253 17.07 18.2587 17.07C17.292 17.07 16.5187 16.7533 15.9387 16.12C15.3587 15.4867 15.0687 14.65 15.0687 13.61C15.0687 12.5633 15.3553 11.7233 15.9287 11.09C16.5087 10.4567 17.282 10.14 18.2487 10.14C19.2287 10.14 20.0053 10.4567 20.5787 11.09C21.152 11.7167 21.4387 12.5567 21.4387 13.61ZM16.4187 13.61C16.4187 14.3167 16.5853 14.8767 16.9187 15.29C17.252 15.7033 17.6987 15.91 18.2587 15.91C18.8253 15.91 19.272 15.7067 19.5987 15.3C19.9253 14.8867 20.0887 14.3233 20.0887 13.61C20.0887 12.8967 19.9253 12.3333 19.5987 11.92C19.272 11.5067 18.822 11.3 18.2487 11.3C17.682 11.3 17.2353 11.5067 16.9087 11.92C16.582 12.3333 16.4187 12.8967 16.4187 13.61ZM26.8152 11.3H25.1052V17H23.8352V11.3H22.1252V10.26H26.8152V11.3Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_2451_16790"
                      x="1"
                      y="0"
                      width="33"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2451_16790"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2451_16790"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2451_16790"
                      x1="17.542"
                      y1="30.984"
                      x2="17.542"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1C75BC" />
                      <stop offset="0.5062" stop-color="#46C8F5" />
                      <stop offset="1" stop-color="#1C75BC" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-7.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__(" All Mine Diamonds ")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2451_16790)">
                    <path
                      d="M5 2V31L17.5 20.7584L30 31V2H5Z"
                      fill="url(#paint0_linear_2451_16790)"
                    />
                  </g>
                  <path
                    d="M13.9395 17H12.6695V14.17H9.89945V17H8.63945V10.26H9.89945V13.08H12.6695V10.26H13.9395V17ZM21.4387 13.61C21.4387 14.65 21.1487 15.4867 20.5687 16.12C19.9953 16.7533 19.2253 17.07 18.2587 17.07C17.292 17.07 16.5187 16.7533 15.9387 16.12C15.3587 15.4867 15.0687 14.65 15.0687 13.61C15.0687 12.5633 15.3553 11.7233 15.9287 11.09C16.5087 10.4567 17.282 10.14 18.2487 10.14C19.2287 10.14 20.0053 10.4567 20.5787 11.09C21.152 11.7167 21.4387 12.5567 21.4387 13.61ZM16.4187 13.61C16.4187 14.3167 16.5853 14.8767 16.9187 15.29C17.252 15.7033 17.6987 15.91 18.2587 15.91C18.8253 15.91 19.272 15.7067 19.5987 15.3C19.9253 14.8867 20.0887 14.3233 20.0887 13.61C20.0887 12.8967 19.9253 12.3333 19.5987 11.92C19.272 11.5067 18.822 11.3 18.2487 11.3C17.682 11.3 17.2353 11.5067 16.9087 11.92C16.582 12.3333 16.4187 12.8967 16.4187 13.61ZM26.8152 11.3H25.1052V17H23.8352V11.3H22.1252V10.26H26.8152V11.3Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_2451_16790"
                      x="1"
                      y="0"
                      width="33"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2451_16790"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2451_16790"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2451_16790"
                      x1="17.542"
                      y1="30.984"
                      x2="17.542"
                      y2="2"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1C75BC" />
                      <stop offset="0.5062" stop-color="#46C8F5" />
                      <stop offset="1" stop-color="#1C75BC" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
          </Slider>
        </div>
      </div>
    </div>
  );
}
