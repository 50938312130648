import React from "react";
import Translator, { __ } from "services/Translator";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default function VipWheel() {
  return (
    <div className="winner_section_v1">
      <div className="container">
        <Tabs
          defaultActiveKey="Bronze Wheel"
          id="fill-tab-example"
          className="winner_tabs vip_wheels"
        >
          <Tab eventKey="Bronze Wheel" title="Bronze Wheel">
            <div className="vip_wheel">
              <div className="row align-items-center">
                <div className="col-lg-4 text-center">
                  <img
                    src={
                      require("assets/images/v1/vip/wheel.svg").default
                    }
                  />
                </div>
                <div className="col-lg-8">
                  <div className="headline">{__("Bronze Wheel")}</div>
                  <div className="desc">
                    {__(
                      "Turnover between $1 and $7,499 and finish down, and the next day you get to spin the Bronze Wheel. Between 0.1% and 5% of your losses will be paid back into your account as soon as the wheel has been spun!"
                    )}
                  </div>
                  <div className="wheel_table">
                    <div className="wheel_single_datarow table_head">
                      <div className="wheel_item">{__("Tier")}</div>
                      <div className="wheel_item">{__("$1 - $7,499")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Turnover Needed")}</div>
                      <div className="wheel_item">{__("5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Top Prize")}</div>
                      <div className="wheel_item">{__("3% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("2nd Prize")}</div>
                      <div className="wheel_item">{__("2% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("3rd Prize")}</div>
                      <div className="wheel_item">{__("1% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("4th Prize")}</div>
                      <div className="wheel_item">{__("Bronze")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("5th Prize")}</div>
                      <div className="wheel_item">{__("0,5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("6th Prize")}</div>
                      <div className="wheel_item">{__("0,1% Cashback")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Silver Wheel" title="Silver Wheel">
            <div className="vip_wheel">
              <div className="row align-items-center">
                <div className="col-lg-4 text-center">
                  <img
                    src={
                      require("assets/images/v1/vip/wheel-silver.svg").default
                    }
                  />
                </div>
                <div className="col-lg-8">
                  <div className="headline">{__("Silver Wheel")}</div>
                  <div className="desc">
                    {__(
                      "Turnover between $1 and $7,499 and finish down, and the next day you get to spin the Bronze Wheel. Between 0.1% and 5% of your losses will be paid back into your account as soon as the wheel has been spun!"
                    )}
                  </div>
                  <div className="wheel_table">
                    <div className="wheel_single_datarow table_head">
                      <div className="wheel_item">{__("Tier")}</div>
                      <div className="wheel_item">{__("$1 - $7,499")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Turnover Needed")}</div>
                      <div className="wheel_item">{__("5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Top Prize")}</div>
                      <div className="wheel_item">{__("3% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("2nd Prize")}</div>
                      <div className="wheel_item">{__("2% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("3rd Prize")}</div>
                      <div className="wheel_item">{__("1% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("4th Prize")}</div>
                      <div className="wheel_item">{__("Bronze")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("5th Prize")}</div>
                      <div className="wheel_item">{__("0,5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("6th Prize")}</div>
                      <div className="wheel_item">{__("0,1% Cashback")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Gold Wheel" title="Gold Wheel">
            <div className="vip_wheel">
              <div className="row align-items-center">
                <div className="col-lg-4 text-center">
                  <img
                    src={
                      require("assets/images/v1/vip/wheel-gold.svg").default
                    }
                  />
                </div>
                <div className="col-lg-8">
                  <div className="headline">{__("Gold Wheel")}</div>
                  <div className="desc">
                    {__(
                      "Turnover between $1 and $7,499 and finish down, and the next day you get to spin the Bronze Wheel. Between 0.1% and 5% of your losses will be paid back into your account as soon as the wheel has been spun!"
                    )}
                  </div>
                  <div className="wheel_table">
                    <div className="wheel_single_datarow table_head">
                      <div className="wheel_item">{__("Tier")}</div>
                      <div className="wheel_item">{__("$1 - $7,499")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Turnover Needed")}</div>
                      <div className="wheel_item">{__("5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Top Prize")}</div>
                      <div className="wheel_item">{__("3% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("2nd Prize")}</div>
                      <div className="wheel_item">{__("2% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("3rd Prize")}</div>
                      <div className="wheel_item">{__("1% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("4th Prize")}</div>
                      <div className="wheel_item">{__("Bronze")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("5th Prize")}</div>
                      <div className="wheel_item">{__("0,5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("6th Prize")}</div>
                      <div className="wheel_item">{__("0,1% Cashback")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Diamond Wheel" title="Diamond Wheel">
            <div className="vip_wheel">
              <div className="row align-items-center">
                <div className="col-lg-4 text-center">
                  <img
                    src={
                      require("assets/images/v1/vip/wheel-diamond.svg").default
                    }
                  />
                </div>
                <div className="col-lg-8">
                  <div className="headline">{__("Diamond Wheel")}</div>
                  <div className="desc">
                    {__(
                      "Turnover between $1 and $7,499 and finish down, and the next day you get to spin the Bronze Wheel. Between 0.1% and 5% of your losses will be paid back into your account as soon as the wheel has been spun!"
                    )}
                  </div>
                  <div className="wheel_table">
                    <div className="wheel_single_datarow table_head">
                      <div className="wheel_item">{__("Tier")}</div>
                      <div className="wheel_item">{__("$1 - $7,499")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Turnover Needed")}</div>
                      <div className="wheel_item">{__("5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("Top Prize")}</div>
                      <div className="wheel_item">{__("3% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("2nd Prize")}</div>
                      <div className="wheel_item">{__("2% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("3rd Prize")}</div>
                      <div className="wheel_item">{__("1% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("4th Prize")}</div>
                      <div className="wheel_item">{__("Bronze")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("5th Prize")}</div>
                      <div className="wheel_item">{__("0,5% Cashback")}</div>
                    </div>
                    <div className="wheel_single_datarow">
                      <div className="wheel_item">{__("6th Prize")}</div>
                      <div className="wheel_item">{__("0,1% Cashback")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
