import Translator, { __ } from "services/Translator";

export default __("Best crypto casino") +
  "," +
  __("Jackpot games") +
  "," +
  __("Crash games") +
  "," +
  __("200% bonus") +
  "," +
  __("Free Spins Casino") +
  "," +
  __("Best Bonus Casino") +
  "," +
  __("Crypto games") +
  "," +
  __("Highest RTP") +
  "," +
  __("Casino tournament") +
  "," +
  __("Online crypto gambling") +
  "," +
  __("Crypto bets") +
  "," +
  __("Bitcoin casino") +
  "," +
  __("Ethereum casino") +
  "," +
  __("Casino winners") +
  "," +
  __("Highest welcome bonus") +
  "," +
  __("Pragmatic casino") +
  "," +
  __("Pragmatic Play games") +
  "," +
  __("Evolution live casino") +
  "," +
  __("Cashback") +
  "," +
  __("Rake back") +
  "," +
  __("Crypto betting") +
  "," +
  __("Dogecoin casino") +
  "," +
  __("OnRamper casino") +
  "," +
  __("PayOp casino") +
  "," +
  __("Free games casino") +
  "," +
  __("Top rewards casino") +
  "," +
  __("Rat race casino") +
  "," +
  __("NFT casino") +
  "," +
  __("Cheeky Chimps") +
  "," +
  __("Cheeky Chimp Casino") +
  "," +
  __("Best cashback casino") +
  "," +
  __("Crypto deposit casino") +
  "," +
  __("Crypto payout casino") +
  "," +
  __("Anonymous play casino") +
  "," +
  __("Refer a friend bonus");
