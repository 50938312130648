import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
export default function HomeBanner(props) {
  return (
    <div className="home_banner_v1">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="earn_block">
              <div className="earn_headline">
                <span className="colored">{__("GET A $2000 BONUS")} </span>
              </div>
              <div className="earn_text">
                {__(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
                )}
              </div>
              <div className="register_link">
                <Link
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn_v1 fit_width text-uppercase text-white"
                >
                  {__("Bonus")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="header_block">
              <div className="header_block_headline">{__("Header")}</div>
              <div className="header_text">
                {__("Lorem Ipsum is simply dummy text of ")}
                <br />
                {__("the printing and typesetting ")}
              </div>

              <div className="card_clock_area">
                {/* <div className="clock_heading">
                  {__("Time left to the finish")}
                </div> */}
                <div className="clock">
                  <div className="item">
                    <div className="box">12</div>
                    <div className="text">{__("Hours")}</div>
                  </div>
                  <div className="item colon">:</div>
                  <div className="item">
                    <div className="box">48</div>
                    <div className="text">{__("Minutes")}</div>
                  </div>
                  <div className="item colon">:</div>
                  <div className="item">
                    <div className="box">20</div>
                    <div className="text">{__("Seconds")}</div>
                  </div>
                </div>
              </div>

              <div className="header_block_flag d-none d-md-block">
                <img
                  src={
                    require("assets/images/v1/banner/banner-flag.png").default
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="offer_block">
              <div className="offer_heading">{__("Offer 1")}</div>
              <div className="offer_text">
                {__("Lorem ipsum dolor sit amet,")}
                <br />
                {__("consectetur adipiscing elit. ")}
              </div>
              <div className="offer_link">
                <Link
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn_v1 fit_width "
                >
                  {__("Go")}
                </Link>
              </div>
              <div className="vector d-none d-md-block">
                <img
                  src={
                    require("assets/images/v1/banner/vector-offer-1.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="offer_block change_bg">
              <div className="offer_heading">{__("Offer 2")}</div>
              <div className="offer_text">
                {__("Lorem ipsum dolor sit amet,")}
                <br />
                {__("consectetur adipiscing elit. ")}
              </div>
              <div className="offer_link">
                <Link
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn_v1 fit_width "
                >
                  {__("Go")}
                </Link>
              </div>
              <div className="vector d-none d-md-block">
                <img
                  src={
                    require("assets/images/v1/banner/vector-offer-2.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
