// var configEnv = "development";
var configEnv = "staging";
// var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "",
    apiUrl: "",
    staticUrl: "",
    liveChatUrl: "",
    fileSourceUrl: "",
    debug: false,
    onRamperApiKey: "", //DO not copy, only for casinobull
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://api.demo-igaming.com",
    apiUrl: "https://api.demo-igaming.com/api/v1",
    staticUrl: "https://api.demo-igaming.com/api/v1",
    liveChatUrl:
      "https://bossoffice.demo-igaming.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://bossoffice.demo-igaming.com/public",
    debug: false,
    onRamperApiKey: "", //DO not copy, only for casinobull
  },

  // staging: {
  //   appUrl: "https://gj.chimptestserver.com",
  //   apiUrl: "https://2casinoapi.chimptestserver.com/api/v1",
  //   staticUrl: "https://2casinoapi.chimptestserver.com/api/v1",
  //   liveChatUrl:
  //     "https://2casinobossoffice.chimptestserver.com/public/livechat/php/app.php?widget-inline",
  //   fileSourceUrl: "https://2casinobossoffice.chimptestserver.com/public",
  //   debug: false,
  //   onRamperApiKey: "", //DO not copy, only for casinobull
  // },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://localhost/casinoapi/api/v1",
    staticUrl: "http://localhost/casinoapi/api/v1",
    liveChatUrl:
      "http://localhost/casinoadmin/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://localhost/casinoadmin/public",
    debug: true,
    onRamperApiKey: "", //DO not copy, only for casinobull
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;
