import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import config from "configs/config";
import { isMobile } from "services/isMobile";
import Translator, { __ } from "services/Translator";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";
// https://docs.cryptoprocessing.com/confirmations-and-limits
export default function Cashier(props) {
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;
  const setUsersWallet = props.setUsersWallet;
  const showInPlay = false;
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const wageringTooltipRef = useRef(null);
  const [wageringTooltipShow, setWageringTooltipShow] = useState(false);

  const fiatMethods = ["USD"];

  const minDeposits = {
    BTC: 0.0001,
    ETH: 0.01,
    BNB: 0.01,
    LTC: 0.01,
    DOGE: 1,
    USDTE: 0.01,
    TRS: 1,
  };
  const confirmationsRequired = {
    BTC: 1,
    ETH: 10,
    BNB: 1,
    LTC: 6,
    DOGE: 6,
    USDTE: 10,
    TRX: 6,
  };

  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  const copyDepositAddress = () => {
    var copyText = document.getElementById("deposit_address");
    navigator.clipboard.writeText(copyText.value);
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  // walletModal
  const [depositWallets, setDepositWallets] = useState([]);
  const [selectedDepositWallet, setSelectedDepositWallet] = useState("BTC");

  const [selectedWithdrawalWallet, setSelectedWithdrawalWallet] =
    useState("BTC");
  const [withdrawalWalletAddress, setWithdrawalWalletAddress] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");

  const [showWalletModal, setShowWalletModal] = useState(false);
  const openWalletModal = async () => {
    if (userBalance?.wallets?.length) {
      setShowWalletModal(true);

      setWithdrawalMessage(null);
      setWithdrawalMessageType("success");

      if (fiatMethods.indexOf(userBalance.wallets[0].currency) === -1) {
        setSelectedDepositWallet(userBalance.wallets[0].currency);
        setSelectedWithdrawalWallet(userBalance.wallets[0].currency);
      }

      var response = await api.get("/deposit/wallets");
      if (response.status === 200) {
        setDepositWallets(response.data);
      }
    }
  };

  const closeWalletModal = () => {
    props.setOpenWalletModalTab(null);
    setShowWalletModal(false);
  };
  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );
  const [hideEmptyWallets, setHideEmptyWallets] = useState(
    ls.get("hideEmptyWallets") ? ls.get("hideEmptyWallets") : false
  );
  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );
  const [showFiatChooserModal, setShowFiatChooserModal] = useState(false);
  const toggleShowBalanceAsFiat = () => {
    if (!showBalanceAsFiat) {
      setShowFiatChooserModal(true);
    }
    ls.set("showBalanceAsFiat", !showBalanceAsFiat);
    setShowBalanceAsFiat(!showBalanceAsFiat);
  };
  const toggleHideEmptyWallets = () => {
    ls.set("hideEmptyWallets", !hideEmptyWallets);
    setHideEmptyWallets(!hideEmptyWallets);
  };
  const fiatToDisplaySelected = (ev) => {
    ls.set("fiatCurrencyToDisplay", ev.target.value);
    setFiatCurrencyToDisplay(ev.target.value);
  };
  const closeFiatChooserModal = () => {
    setShowFiatChooserModal(false);
  };

  const [withdrawalMessage, setWithdrawalMessage] = useState(null);
  const [withdrawalMessageType, setWithdrawalMessageType] = useState("success");

  const withdraw = async () => {
    if (!withdrawalWalletAddress) {
      setWithdrawalMessage(
        __("Please enter the") +
          " " +
          selectedWithdrawalWallet +
          " " +
          __("address for withdrawal")
      );
      setWithdrawalMessageType("danger");
      return false;
    }
    var amount = parseFloat(withdrawalAmount);
    if (!amount || isNaN(amount)) {
      setWithdrawalMessage(__("Please enter a valid amount for withdrawal"));
      setWithdrawalMessageType("danger");
      return false;
    } else if (parseFloat(amount) < 50) {
      setWithdrawalMessage(__("Minimum amount for withdrawal is $50.00"));
      setWithdrawalMessageType("danger");
      return false;
    }
    var postData = {
      amount: amount,
      method: "COINSPAID:" + selectedWithdrawalWallet,
      accNumber: withdrawalWalletAddress,
    };
    var response = await api.post("/withdraw", postData);
    if (response.status === 200) {
      setWithdrawalAmount("");
      setWithdrawalWalletAddress("");
      setWithdrawalMessage(__("Your withdrawal request has been received"));
      setWithdrawalMessageType("success");

      setTimeout(() => {
        setWithdrawalMessage(null);
      }, 3000);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  // wallet tabs
  const [activeWalletTab, setActiveWalletTab] = useState("deposit");
  const toggleWalletTab = (tabName) => {
    setActiveWalletTab(tabName);
  };

  //Open from other elements
  useEffect(() => {
    if (props.openWalletModalTab) {
      if (props.openWalletModalTab === "deposit") {
        openWalletModal();
        setActiveWalletTab("deposit");
      } else if (props.openWalletModalTab === "withdraw") {
        openWalletModal();
        setActiveWalletTab("withdraw");
      }
    } else {
      closeWalletModal();
    }
  }, [props.openWalletModalTab]);

  const userDetails = props.userDetails;

  const [onRamperWidgetUrl, setOnRamperWidgetUrl] = useState(
    "https://widget.onramper.com?color=266677&apiKey="
  );

  const updateOnRamperWidgetUrl = () => {
    var url = "https://widget.onramper.com?color=266677";
    url += "&apiKey=" + config.onRamperApiKey;
    url += "&onlyGateways=Xanpool,Itez";
    url += "&supportSell=false";
    url += "&isAddressEditable=true";
    url += "&defaultCrypto=" + selectedDepositWallet;

    var cryptos = userBalance.wallets.map((item, index) => item.currency);
    var wallets = Object.entries(depositWallets)
      .filter((item) => fiatMethods.indexOf(item.currency) === -1)
      .map((item, index) => item[0] + ":" + item[1].address)
      .join(",");
    console.log(wallets);
    url += "&onlyCryptos=" + cryptos;
    url += "&wallets=" + wallets;
    setOnRamperWidgetUrl(url);
  };

  useEffect(() => {
    updateOnRamperWidgetUrl();
  }, [activeWalletTab, depositWallets, selectedDepositWallet]);

  return (
    <>
      <div className="deposit_block">
        <Dropdown>
          <Dropdown.Toggle
            className="right wallets_dropdown"
            id="dropdown-basic1"
          >
            {!showInPlay && (
              <div className="deposit_amount">
                {!!userBalance?.wallets?.length
                  ? !userBalance.wallets[0].balance && userBalance.bonus_balance
                    ? userBalance.currency + userBalance.bonus_balance
                    : showBalanceAsFiat
                    ? moneyFormatter.convertCurrencyToFiat(
                        userBalance.wallets[0].balance,
                        userBalance.wallets[0].currency,
                        fiatCurrencyToDisplay
                      )
                    : parseFloat(userBalance.wallets[0].balance).toFixed(8)
                  : ""}
              </div>
            )}

            {showInPlay && (
              <div onClick={() => getUserBalance()} className="deposit_amount">
                (In Play)
              </div>
            )}

            <div className="deposit_icon">
              {" "}
              {!!userBalance?.wallets?.length &&
                moneyFormatter.currencyIcon(userBalance.wallets[0].currency)}
            </div>
            <div className="wallet_caret">
              <i className="fal downarrow fa-caret-down"></i>
              <i className="fal uparrow fa-caret-up"></i>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="wallets_menu desktop">
            <div className="modal_top_arrow"></div>
            <div>
              <div className="wallets_head row">
                <div className="col-6"></div>
                <div className="col-6 text-right">{__("Crypto")}</div>
              </div>

              {!!userBalance?.wallets?.length &&
                userBalance.wallets.map((item, index) => {
                  if (!item.balance && hideEmptyWallets) {
                    return null;
                  }
                  return (
                    <Dropdown.Item as="button" key={index}>
                      <div
                        className="wallets_balance row"
                        key={index}
                        onClick={() => setUsersWallet(item.currency)}
                      >
                        <div className="col-6">
                          {showBalanceAsFiat
                            ? moneyFormatter.convertCurrencyToFiat(
                                item.balance,
                                item.currency,
                                fiatCurrencyToDisplay
                              )
                            : parseFloat(item.balance).toFixed(8)}
                        </div>
                        <div className="col-6 currency">
                          {moneyFormatter.currencyWithIcon(item.currency)}
                        </div>
                      </div>
                    </Dropdown.Item>
                  );
                })}

              {userBalance.bonus_balance && (
                <Dropdown.Item as="button">
                  <div className="wallets_balance row">
                    <div className="col-6">
                      {userBalance.currency +
                        "" +
                        parseFloat(userBalance.bonus_balance).toFixed(2)}
                    </div>

                    <div
                      ref={wageringTooltipRef}
                      onMouseOver={() => setWageringTooltipShow(true)}
                      onMouseOut={() => setWageringTooltipShow(false)}
                      className="col-6 text-right currency"
                    >
                      <span className="currency_icon">
                        <img
                          style={{ width: "20px" }}
                          src={
                            require("assets/images/icons/star-icon.svg")
                              .default
                          }
                          alt={__("Bonus")}
                        />
                      </span>
                      <span className="currency_name">{__("BONUS")}</span>

                      <br />
                    </div>
                    <div className="progress_bar">
                      <div className="progress">
                        <div
                          style={{
                            width: userBalance.wagering_percentage + "%",
                          }}
                          className="progress-bar"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              <div className="wallets_bottom row">
                <div className="wallets_switch">
                  <div className="col-11 offset-1">
                    <Form.Check
                      checked={showBalanceAsFiat}
                      type="switch"
                      onChange={toggleShowBalanceAsFiat}
                      label="Show as fiat"
                      id="show_as_fiat"
                    />
                  </div>
                </div>

                <div className="wallets_switch">
                  <div className="col-11 offset-1">
                    <Form.Check
                      checked={hideEmptyWallets}
                      type="switch"
                      label="Hide empty wallets"
                      id="hide_empty_wallets"
                      onChange={toggleHideEmptyWallets}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="deposit_button d-md-none">
                    <a
                      onClick={openWalletModal}
                      className="deposit_btn"
                      href="#"
                    >
                      {__("Wallet")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <div className="deposit_button d-none d-md-block">
          <a onClick={openWalletModal} className="deposit_btn" href="#">
            {__("Wallet")}
          </a>
        </div>
      </div>
      <Modal show={showFiatChooserModal} onHide={closeFiatChooserModal}>
        <Modal.Body
          className={"login_modal fiat_selectors_modal " + props.theme}
        >
          <div onClick={closeFiatChooserModal} className="modal_close">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20L19.3848 1.61522"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M1 2L19.3848 20.3848"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>

          <div className="modal_headline">{__("Information")}</div>
          <>
            <div className="privacy_text">
              {__("Please note that these are currency approximations.")}
              <br />
              <br />
              {__(
                "All bets & transactions will be settled in the crypto equivalent. For further details feel free to contact our live support."
              )}
            </div>

            <div className="form_area">
              <div className="form_row fiat_selectors">
                {["USD", "EUR"].map((currency, index) => (
                  <div className="fiat_select" key={index}>
                    <input
                      type="radio"
                      value={currency}
                      checked={fiatCurrencyToDisplay === currency}
                      id={"fiat_selector" + currency}
                      onChange={fiatToDisplaySelected}
                    />
                    <label
                      for={"fiat_selector" + currency}
                      className="radio_area"
                    >
                      <div className="radio_check_and_text_area">
                        <div className="radio_box">
                          <div className="radio_checked_box"></div>
                        </div>
                        <div className="radio_text">
                          {moneyFormatter.currencyIcon(currency)}{" "}
                          <span className="currency">{currency}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="login_button">
                <Link
                  hrefLang={Translator.getSelectedLanguage().code}
                  to="#"
                  onClick={closeFiatChooserModal}
                  className="common_btn_v1 fit_width margin_auto"
                >
                  {__("Play Now")}
                </Link>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={showWalletModal} onHide={closeWalletModal}>
        <Modal.Body className={"login_modal " + props.theme}>
          <div onClick={closeWalletModal} className="modal_close">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20L19.3848 1.61522"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M1 2L19.3848 20.3848"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>

          <div className="modal_headline">Wallet</div>
          <div className="login_mode in_deposit_modal">
            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              to="#"
              onClick={() => toggleWalletTab("deposit")}
              className={
                activeWalletTab === "deposit" ? "mode_btn active" : "mode_btn"
              }
            >
              {__("Deposit")}
            </Link>
            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              to="#"
              onClick={() => toggleWalletTab("withdraw")}
              className={
                activeWalletTab === "withdraw" ? "mode_btn active" : "mode_btn"
              }
            >
              {__("Withdraw")}
            </Link>
            {(userDetails.userId === 8 || userDetails.userId === 3) && ( //Dennis and Dirk
              <Link
                hrefLang={Translator.getSelectedLanguage().code}
                to="#"
                onClick={() => toggleWalletTab("buy_crypto")}
                className={
                  activeWalletTab === "buy_crypto"
                    ? "mode_btn active"
                    : "mode_btn"
                }
              >
                {__("Buy Crypto")}
              </Link>
            )}
          </div>

          {activeWalletTab === "deposit" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">{__("Cryptocurrency")}</div>

                  <Dropdown className="currency_select_menu ">
                    <Dropdown.Toggle
                      variant="success"
                      className="form-control name_value text-left"
                      id="dropdown-basic1"
                    >
                      {moneyFormatter.currencyFullWithIcon(
                        selectedDepositWallet
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!userBalance?.wallets?.length &&
                        userBalance.wallets
                          .filter(
                            (item) => fiatMethods.indexOf(item.currency) === -1
                          )
                          .map((item, index) => {
                            return (
                              <Dropdown.Item as="button" key={index}>
                                <div
                                  className="wallets_balance"
                                  onClick={() =>
                                    setSelectedDepositWallet(item.currency)
                                  }
                                >
                                  {moneyFormatter.currencyFullWithIcon(
                                    item.currency
                                  )}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="privacy_text bordered_text">
                  {__("The minimum deposit amount for")}{" "}
                  {moneyFormatter.currencyName(selectedDepositWallet)} is{" "}
                  {moneyFormatter.currencyIcon(selectedDepositWallet)}{" "}
                  {minDeposits[selectedDepositWallet]} ($
                  {Math.ceil(
                    moneyFormatter.getFiatValue(
                      minDeposits[selectedDepositWallet],
                      selectedDepositWallet,
                      "USD"
                    )
                  ).toFixed(2)}{" "}
                  {__("approximately")}).{" "}
                  {__(
                    "Depositing a lower amount may result in a full loss of the transferred amount."
                  )}
                  <br />
                  {__("Only send")} {selectedDepositWallet}{" "}
                  {__("to this address")},{" "}
                  {confirmationsRequired[selectedDepositWallet]}{" "}
                  {__("confirmation")}
                  {confirmationsRequired[selectedDepositWallet] > 1
                    ? "s"
                    : ""}{" "}
                  {__("requried")}. {__("We do not accept BEP20 from Binance")}.
                </div>

                <div className="input_item walletAddress">
                  <div className="name">
                    {selectedDepositWallet} {__("Address")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    name="btc"
                    id="deposit_address"
                    value={
                      depositWallets[selectedDepositWallet]
                        ? depositWallets[selectedDepositWallet].address
                        : ""
                    }
                    className="form-control name_value"
                  />

                  <a
                    className="in_input_icon copy"
                    onClick={copyDepositAddress}
                    href="javascript:"
                    ref={target}
                  >
                    <img
                      alt={__("Copy")}
                      src={require("assets/images/icons/copy-icon.svg").default}
                    />
                  </a>
                  <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                      <Tooltip
                        id="overlay-example"
                        className="tooltip"
                        {...props}
                      >
                        {__("Copied")}
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
                <div className="input_item">
                  <div className="qr_code in_wallet_modal text-center">
                    <img
                      className="wallet_qr"
                      alt={
                        depositWallets[selectedDepositWallet]
                          ? depositWallets[selectedDepositWallet].qr_text
                          : ""
                      }
                      src={
                        config.apiUrl +
                        "/qr-code/" +
                        (depositWallets[selectedDepositWallet]
                          ? depositWallets[selectedDepositWallet].qr_text
                          : "")
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "withdraw" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">{__("Cryptocurrency")}</div>

                  {withdrawalMessage && (
                    <Alert variant={withdrawalMessageType}>
                      {withdrawalMessage}
                    </Alert>
                  )}

                  <Dropdown className="currency_select_menu">
                    <Dropdown.Toggle
                      variant="success"
                      className="form-control name_value text-left"
                      id="dropdown-basic1"
                    >
                      {moneyFormatter.currencyFullWithIcon(
                        selectedWithdrawalWallet
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!userBalance?.wallets?.length &&
                        userBalance.wallets
                          .filter(
                            (item) => fiatMethods.indexOf(item.currency) === -1
                          )
                          .map((item, index) => {
                            return (
                              <Dropdown.Item as="button" key={index}>
                                <div
                                  className="wallets_balance"
                                  onClick={() =>
                                    setSelectedWithdrawalWallet(item.currency)
                                  }
                                >
                                  {moneyFormatter.currencyFullWithIcon(
                                    item.currency
                                  )}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="privacy_text bordered_text">
                  {__("Minimum withdrawal is")} $50.00.
                </div>
                <div className="input_item">
                  <div className="name">
                    {selectedWithdrawalWallet} {__("Address")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    value={withdrawalWalletAddress}
                    onChange={(ev) =>
                      setWithdrawalWalletAddress(ev.target.value)
                    }
                    className="form-control name_value"
                  />
                  {/* <i className="fal fa-copy in_input_icon"></i> */}
                </div>
                <div className="input_item">
                  <div className="name">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control name_value"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={withdrawalAmount}
                      onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    />
                    <div className="currency_name">USD</div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setWithdrawalAmount(
                            userBalance.wallets[0].balance_fiat.replace(",", "")
                          );
                        }}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="login_button">
                  <Link
                    hrefLang={Translator.getSelectedLanguage().code}
                    disabled={withdrawalAmount < 50 || !withdrawalWalletAddress}
                    onClick={withdraw}
                    className={
                      withdrawalAmount < 50 || !withdrawalWalletAddress
                        ? "common_btn_v1 fit_width margin_auto inactive"
                        : "common_btn_v1 fit_width margin_auto"
                    }
                  >
                    {withdrawalAmount >= 50
                      ? __("Withdraw") + " $" + withdrawalAmount
                      : __("Min amount is") + " $50.00"}
                  </Link>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "buy_crypto" && (
            <>
              <div className="privacy_text bordered_text">&nbsp;</div>
              <div className="form_area">
                <div
                  style={{
                    justifyContent: "center",
                    padding: "15",
                    width: "800px",
                    maxWidth: "90vw",
                  }}
                >
                  <iframe
                    style={{
                      borderRadius: 10,
                      boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                      margin: "auto",
                      width: "100%",
                      maxWidth: "90vw",
                      height: "660px",
                    }}
                    src={onRamperWidgetUrl}
                    title="Onramper widget"
                    frameBorder="0"
                    allow="accelerometer; autoplay; camera; gyroscope; payment"
                  ></iframe>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
