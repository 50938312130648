import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

export default function BottomBanner(props) {
  return (
    <div className="bottom_banner_v1 home_banner_v1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="offer_block vip">
              <div className="offer_heading">{__("VIP banner")}</div>
              <div className="offer_text">
                {__("Lorem ipsum dolor sit amet,")}
                <br />
                {__("consectetur adipiscing elit. ")}
              </div>
              <div className="offer_link">
                <Link
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn_v1 fit_width "
                >
                  {__("Go")}
                </Link>
              </div>
              <div className="vector d-none d-md-block">
                <img
                  src={
                    require("assets/images/v1/banner/diamond.png")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="offer_block promotion">
              <div className="offer_heading">{__("Promotion banner")}</div>
              <div className="offer_text">
                {__("Lorem ipsum dolor sit amet,")}
                <br />
                {__("consectetur adipiscing elit. ")}
              </div>
              <div className="offer_link">
                <Link
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn_v1 fit_width "
                >
                  {__("Go")}
                </Link>
              </div>
              <div className="vector d-none d-md-block">
                <img
                  src={
                    require("assets/images/v1/banner/giftbox.png")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
