import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import Slider from "react-slick";
import Translator, { __ } from "services/Translator";
const Banner = (props) => {
  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    arrows: false,
    fade: true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [homeImages, setHomeImages] = useState(null);

  const getHomePageImages = async () => {
    var response = await api.get("/homepage-images");
    if (response.status === 200) {
      setHomeImages(response.data);
    }
  };

  useEffect(() => {
    getHomePageImages();
  }, []);

  //Get Banners
  const [banners, setBanners] = useState([]);
  const getBanners = async (ev) => {
    var response = await api.get("/banners");
    setBanners(response.data);
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      <section class="banner_section">
        <div class="banner_area">
          {/* <Slider {...settings1}>
            <div class="single_banner ">
              <img
                src={require("assets/images/banners/wr-banner1.jpg").default}
                alt=""
              />
              <div class="banner_content">
                <div class="main_title">{__("First Banner Heading")}</div>
                <a class="btn btn-success registration_btn " href="javascript:">
                  {__("Sign Up")}
                </a>
              </div>
            </div>

            <div class="single_banner ">
              <img
                src={require("assets/images/banners/wr-banner2.jpg").default}
                alt=""
              />
              <div class="banner_content">
                <div class="main_title">{__("Second Banner Heading")}</div>
                <a class="btn btn-success registration_btn" href="javascript:">
                  {__("Sign Up")}
                </a>
              </div>
            </div>
          </Slider> */}
          <Slider {...settings1}>
            {banners?.map((banner, index) => (
              <>
                
                <div className="single_banner">
                  <img
                    className="banner_image desktop_banner"
                    src={banner.banner}
                  />
                  <img
                    className="banner_image tab_banner"
                    src={banner.medium}
                  />
                  <img
                    className="banner_image mobile_banner"
                    src={banner.small}
                  />
                  <div className="banner_content_area ">
                    <h1 className="banner_title">{banner.title}</h1>
                    
                    <div className="banner_sub_text">
                      {banner.sub_title}
                      {/* {__("Fortune Casino")}
                  <br />
                  {__("LIVE CASINO TOURNAMENT")} */}
                    </div>

                    {props.isAuthenticated && (
                      <Link className="registration_btn float-right" to="/games/slots">
                        {__("Play Now!")}
                      </Link>
                    )}

                    {!props.isAuthenticated && (
                      <Link
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                        className="registration_btn float-right"
                      >
                        {__("Sign Up")}
                      </Link>
                      
                    )}
                  </div>
                </div>
              </>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Banner;
