import React, { useState, useEffect } from "react";
import Banner from "../elements/Banner";
import { Link } from "react-router-dom";
import api from "../services/api";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";


export default function Stores(props) {
  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  // const [recentArticleData, setRecentArticleData] = useState({});
  // const getStoresData = async (ev) => {
  //   var response = await api.get("/products");
  //   if (response.status === 200) {
  //     setRecentArticleData(response.data.data);
  //   }
  //   console.log("articleData", response.data);
  // };

  // useEffect(() => {
  //   getStoresData();
  // }, []);

  //pagination data

  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);

  const pageNum = (receiveVal) => {
    setPage(receiveVal);
  };

  const [productsData, setProductsData] = useState({});
  const [productsDataPagination, setProductsDataPagination] = useState([]);
  const getProductsData = async (ev) => {
    var response = await api.post("/products", {
      page: page,
      perPage: 8,
    });
    if (response.status === 200) {
      setActivePage(page);
      setProductsData(response.data.data);
      setProductsDataPagination(response.data.paginationData);
    }
    console.log("productsData", response.data);
  };
  useEffect(() => {
    getProductsData();
  }, [page]);

  const buyProduct = async (id) => {
    props.setSpinner(true);
    var response = await api.post("/buy-product", { product_id: id });
    console.log(response);
    props.setSpinner(false);
    if (response.status == 200) {
      props.showAlert(__("Success"), response.data.message, false, null, 5000);
    } else {
      props.showAlert(__("Error!!"), response.data.message, true);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  return (
    <>
     
      <div className="products">
        <div className="container">
          <PageHeadline page_name={__("Products")} />


          <div className="row">
            <div className="col-md-12">
              <div className="product_lists">
                {productsData.length > 0 &&
                  productsData.map((value, index) => (
                    <div className="single_product">
                      <div className="product_img">
                        <img className="single_games_img" src={value.background_path} />
                      </div>
                      <div class="product_details">
                        <h3 class="product_heading">{value.title}</h3>
                        {value.free_spins && (
                          <h3 class="product_part_info">
                            {value.free_spins} {__("Free Spins")}
                          </h3>
                        )}

                        {value.bonus_money && (
                          <h3 class="product_part_info">
                            {value.bonus_money} {__("Bonus Money")}
                          </h3>
                        )}

                        {value.cash_money && (
                          <h3 class="product_part_info">
                            {value.cash_money} {__("Cash Money")}
                          </h3>
                        )}

                        {value.price && (
                          <h3 class="product_part_info">
                            {" "}
                            {__("Price")}: {value.price} {value.price > 1 ? "Coins" : "Coin"}
                          </h3>
                        )}
                      </div>
                      <Link onClick={() => buyProduct(value.id)} className="common_btn_v1 fit_width margin_auto buy_products">
                        {__("buy product")}
                      </Link>
                    </div>
                  ))}
              </div>
              <br />
              <Pagination data={productsDataPagination} onPageChange={onPageChange} />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
