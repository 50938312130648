import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function PromotionsBanner() {
  return (
    <div className="container">
      <PageHeadline page_name="Promotions" />
      <div className="promotion_banner">
        <div className="row align-items-center">
          <div className="col-12 col-lg-12 col-xl-4">
            <div className="headline">{__("GET A $5000 BONUS")}</div>
            <div className="text">
              {__(
                "Boost your deposits with over 625% in Bonuses and 1400 Free Spins!"
              )}
            </div>
            <div className="links">
              <Link className="common_btn_v1 fit_width">{__("Go")}</Link>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-xl-2">
            <div className="promotion_deposit_block">
              <div className="title">
                1<sup>{__("st ")}</sup>
                {__("Deposit")}
              </div>
              <div className="dsc">{__("50% up to 1.2 BTC!")}</div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-xl-2">
            <div className="promotion_deposit_block item_2">
              <div className="title">
                2<sup>{__("nd ")}</sup>
                {__("Deposit")}
              </div>
              <div className="dsc">{__("60% up to 2.5 BTC!")}</div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-xl-2">
            <div className="promotion_deposit_block item_3">
              <div className="title">
                3<sup>{__("rd ")}</sup>
                {__("Deposit")}
              </div>
              <div className="dsc">{__("70% up to 3.1 BTC!")}</div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-xl-2">
            <div className="promotion_deposit_block item_4">
              <div className="title">
                4<sup>{__("th ")}</sup>
                {__("Deposit")}
              </div>
              <div className="dsc">{__("80% up to 4.2 BTC!")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
