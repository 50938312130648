import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import { Button, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import api from "services/api";
import ReactApexChart from "react-apexcharts";
import Translator, { __ } from "services/Translator";

export default function Dashboard(props) {
  const [nfts, setNfts] = useState([]);
  const [dashboardData, setDashboardData] = useState({});

  const getNfts = async () => {
    var response = await api.get("/nft/nfts/list");
    if (response.status === 200) {
      setNfts(response.data);
    }
  };

  const getDashboardData = async () => {
    var response = await api.get("/nft/dashboard");
    if (response.status === 200) {
      console.log(response.data);
      setDashboardData(response.data);
    }
  };
  const claimBalance = async () => {
    if (!window.confirm(__("Do you confirm to claim your available reward to your player account?"))) {
      return;
    }
    props.setSpinner(true);
    var response = await api.get("/nft/claim-balance");
    props.setSpinner(false);
    if (response.status === 200) {
      console.log(response.data);
      setDashboardData({ ...dashboardData, nft_balance: "0.00" });
    }
  };

  useEffect(() => {
    getNfts();
    getDashboardData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };
  return (
    <div className="backend">
      <div className="dashboard_content">
        <>
          <div className="dasboard_home">
            <div className="row">
              <div className="col-lg-3">
                <Slider {...settings}>
                  {nfts.map((item, index) => (
                    <div className="slider_item" key={index}>
                      <h6 className="text-white uppercase game_name">
                        {item.metadata.name} ({index + 1} of {nfts.length})
                      </h6>
                      <img src={item.metadata.image} alt={item.metadata.name} />

                      <div className="crp_data">
                        <div className="crp_datarow">
                          {__("CRP Monthly Percentage")}: {item.profit_percent}%
                        </div>
                        <div className="crp_datarow">
                          {__("CRP Share This Month")}: ${item.crp_amount}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">
                          {dashboardData?.crp_pool === "0.00" ? "TBA" : "$" + dashboardData?.crp_pool}
                        </h2>
                        <h6 className="text-white">
                          {__("Current Month")} <br />
                          {__("Casino Reward Pool (CRP)")}
                        </h6>
                      </div>
                      <div className="icon"></div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">
                          {dashboardData?.crp_pool === "0.00" ? "TBA" : "$" + dashboardData?.total_nft_earning}
                        </h2>
                        <h6 className="text-white">
                          {__("NFT Holders")} <br />
                          {__("Casino Reward Pool Split")}
                        </h6>
                      </div>
                      <div className="icon"></div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">${dashboardData?.lifetime_crp_total}</h2>
                        <h6 className="text-white">{__("Your Lifetime CRP Earning")}</h6>
                      </div>
                      <div className="icon"></div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">
                          {dashboardData?.crp_pool === "0.00" ? "TBA" : "$" + dashboardData?.own_nft_earning}
                        </h2>
                        <h6 className="text-white">{__("Your Current Month CRP Split")}</h6>
                      </div>
                      <div className="icon"></div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">
                          {dashboardData?.crp_pool === "0.00" ? "TBA" : "$" + dashboardData?.marketing_pool}
                        </h2>
                        <h6 className="text-white">
                          {__("Current Month")} <br />
                          {__("Marketing Pool")}
                        </h6>
                      </div>
                      <div className="icon"></div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">{"$" + dashboardData?.lifetime_marketing_pool}</h2>
                        <h6 className="text-white">
                          {__("Lifetime")} <br />
                          {__("Marketing Pool")}
                        </h6>
                      </div>
                      <div className="icon"></div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <div className="text_area">
                        <h2 className="text-white bold">${dashboardData?.nft_balance}</h2>
                        <h6 className="text-white">{__("Available To Claim")}</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div className="block summary">
                      <button className="common_btn claim_button" onClick={claimBalance}>
                        {__("CLAIM")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </>
      </div>
    </div>
  );
}
