import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import GameImage from "elements/games_elements/GameImage";
import api from "services/api";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";

export default function GamingHistory(props) {
  const defaultForm = {
    type: "",
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/gaming", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const trTypes = [
    { label: __("All"), value: null },
    { label: __("Bet"), value: "debit" },
    { label: __("Win"), value: "credit" },
  ];

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <div className="user_dashboard">
        <div className="container">
          <PageHeadline page_name={__("Gaming History")} />
          <div className="page_content_area">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="input_item">
                  <div className="name">
                    <i className="far fa-folder-open"></i>{" "}
                    {__("Transaction For")}
                  </div>
                  <SelectBox
                    className="form-control name_value"
                    value={form.type}
                    onChange={(itemValue) => updateForm(itemValue, "type")}
                    items={trTypes}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="input_item">
                  <div className="name">
                    <i className="far fa-calendar-alt"></i> {__("Start Day")}
                  </div>
                  <div className="row">
                    <DateSelector
                      className="filter_group"
                      value={form.startDate}
                      onChange={(itemValue) =>
                        updateForm(itemValue, "startDate")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="input_item">
                  <div className="name">
                    <i className="far fa-calendar-alt"></i> {__("End Day")}
                  </div>
                  <div className="row">
                    {" "}
                    <DateSelector
                      className="filter_group"
                      value={form.endDate}
                      onChange={(itemValue) => updateForm(itemValue, "endDate")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="button_group">
                  <Link
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="#"
                    onClick={getResults}
                    className="common_btn_v1 fit_width"
                  >
                    {__("Search")}
                  </Link>
                  <Link
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="#"
                    onClick={doReset}
                    className="common_btn_v1 fit_width red"
                  >
                    {__("Reset")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="table_area">
              {/* desktop */}
              <div className="d-none d-sm-block">
                <div className="heading_row game_history">
                  <div className="item">{__("Game")}</div>
                  <div className="item">{__("Type")}</div>
                  <div className="item">{__("Balance Type")}</div>
                  <div className="item">{__("Freeround")}</div>
                  <div className="item">{__("Amount")}</div>
                  <div className="item">{__("Cash Balance Before")}</div>
                </div>
                {results.length ? (
                  <>
                    {results.map((item, index) => (
                      <div key={index} className="data_row game_history">
                        <div className="item">
                          <GameImage
                            className="game_img"
                            src={item.game?.game_snapshot_url}
                          />
                          <br />
                          {item.game?.game_name}
                          <br />
                          {item.record_date}
                        </div>
                        <div className="item">{item.type}</div>
                        <div className="item">{item.using_bonus}</div>
                        <div className="item">{item.is_freeround}</div>
                        <div className="item">
                          <span className="table_btn">{item.amount}</span>
                        </div>
                        <div className="item">
                          <span className="table_btn">
                            {item.balance_before}
                          </span>
                        </div>
                      </div>
                    ))}

                    <div className="pagination">
                      <Pagination
                        data={pagination}
                        onPageChange={onPageChange}
                      />
                    </div>
                  </>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
              {/* mobile */}
              <div className="mobile_accordion d-block d-sm-none">
                {results.length ? (
                  <>
                    <Accordion>
                      {results.map((item, index) => (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            {item.game?.game_name}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion_content">
                              <div className="input_item">
                                <div className="name">{__("Game")}</div>
                                <div className="form-control name_value">
                                  {item.game?.game_name}
                                </div>
                              </div>
                              <div className="input_item">
                                <div className="name">{__("Type")}</div>
                                <div className="form-control name_value">
                                  {item.type}
                                </div>
                              </div>
                              <div className="input_item">
                                <div className="name">{__("Balance Type")}</div>
                                <div className="form-control name_value">
                                  {item.using_bonus}
                                </div>
                              </div>
                              <div className="input_item">
                                <div className="name">{__("Freeround")}</div>
                                <div className="form-control name_value">
                                  {item.is_freeround}
                                </div>
                              </div>
                              <div className="input_item">
                                <div className="name">{__("Amount")}</div>
                                <div className="form-control name_value">
                                  {item.amount}
                                </div>
                              </div>

                              <div className="input_item">
                                <div className="name">
                                  {__("Cash Balance Before")}
                                </div>
                                <div className="form-control name_value">
                                  {item.balance_before}
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>

                    <br />
                    <br />
                    <div className="pagination">
                      <Pagination
                        data={pagination}
                        onPageChange={onPageChange}
                      />
                    </div>
                  </>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
