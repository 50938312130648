import React from "react";
import Translator, { __ } from "services/Translator";
import Logo from "elements/icons/Logo";

class SpinnerComponent extends React.Component {
  state = {
    loading: true,
  };
  render() {
    return (
      <div className="spinner_overlay">
        {/* <Logo className="spinner" /> */}
        <img
          className="spinner"
          src={require("assets/images/v1/site-logo.png").default}
        />
      </div>
    );
  }
}

export default SpinnerComponent;
