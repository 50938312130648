import React, { useState, useEffect } from "react";
import Banner from "../elements/Banner";
import { Link } from "react-router-dom";
import api from "../services/api";
import Pagination from "../elements/Pagination";
import Translator, { __ } from "services/Translator";
import { Badge, Button, Accordion } from "react-bootstrap";
import InputBox from "elements/InputBox";
import PageHeadline from "../elements/PageHeadline";

export default function Tickets(props) {
  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  //pagination data

  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);

  const pageNum = (receiveVal) => {
    setPage(receiveVal);
  };

  //Get Tickets functionality

  const [tickets, setTickets] = useState([]);
  const getTickets = async () => {
    props.setSpinner(true);
    var response = await api.get("/my-tickets");
    props.setSpinner(false);
    if (response.status === 200) {
      setTickets(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  //Create tickets
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const updateForm = (value, field) => {
    if (isAuthenticated && (field === "email" || field === "name")) {
      return false;
    }
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setForm({
        ...form,
        email: userDetails.email,
        name: userDetails.first_name + " " + userDetails.last_name,
      });
    }
  }, [userDetails]);

  const sendMail = async () => {
    props.setSpinner(true);
    var response = await api.post("/create-ticket", form);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setForm({ ...form, subject: "", message: "" });
      getTickets();
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  return (
    <>
      <div className="container">
        <div className="ticket_page">
          <PageHeadline page_name={__("Create Ticket")} />
          <div className="page_content_area">
            <div className="contact_us_area">
              <div className="row">
                <div className="reg_field col-sm-6">
                  <div className="input_item">
                    <div className="name">{__("Full Name")}</div>
                    <InputBox
                      type="text"
                      className="form-control name_value"
                      readOnly={isAuthenticated}
                      required={true}
                      value={form.name}
                      onChange={(value) => updateForm(value, "name")}
                      placeholder={__("Full Name")}
                    />
                  </div>
                </div>
                <div className="reg_field col-sm-6">
                <div className="input_item">
                <div className="name">{__("Email")}</div>
                  <InputBox
                    type="email"
                    className="form-control name_value"
                    readOnly={isAuthenticated}
                    required={true}
                    value={form.email}
                    onChange={(value) => updateForm(value, "email")}
                    placeholder={__("Email")}
                  />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="reg_field col-sm-12">
                <div className="input_item">
                <div className="name">{__("Subject")}</div>
                  <InputBox
                    required
                    type="text"
                    className="form-control name_value"
                    value={form.subject}
                    onChange={(value) => updateForm(value, "subject")}
                    placeholder={__("Subject")}
                  />
                  </div>
                </div>
              </div>

              <div className="reg_field">
              <div className="input_item">
              <div className="name">{__("Message")}</div>
                <textarea
                  required
                  name="message"
                  className="form-control name_value text_area"
                  rows="4"
                  value={form.message}
                  onChange={(ev) => updateForm(ev.target.value, "message")}
                  placeholder={__("Message")}
                ></textarea>
                </div>
              </div>
              <input type="hidden" name="g-recaptcha-response" value="" />
              <div className="reg-submit">
                <button
                  onClick={sendMail}
                  className="common_btn_v1 fit_width margin_auto"
                >
                  {__("Submit")}
                </button>
              </div>
            </div>

            <div className="faq_bottom_area">
              {!!tickets.total && (
                <div className="header">{__("Last Tickets")}</div>
              )}
              <div className="chimp-accordions access_control_area">
                <Accordion>
                  {!!tickets.total ? (
                    <>
                      {tickets.openTickets.map((item, index) => (
                        <>
                          <Accordion.Item
                            key={index}
                            eventKey={index}
                            className="chimp-accordion"
                          >
                            <Accordion.Header className="faq__question">
                              <Link
                                onClick={() => {
                                  props.setSupportSidebarOpen(false);
                                }}
                                to={"/ticket-details/" + item.id}
                              >
                                {__("Ticket")}#{item.id}-{item.title}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body className="faq__answer">
                              {item.created_at}
                              <br />
                              <br />
                              {item.body}
                              <Link
                                to={"/ticket-details/" + item.id}
                                className="common_btn common_brown_button"
                              >
                                {__("Details")}
                              </Link>
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      ))}

                      {tickets.solvedTickets.map((item, index) => (
                        <>
                          <Accordion.Item
                            key={index}
                            eventKey={index}
                            className="chimp-accordion"
                          >
                            <Accordion.Header
                              onClick={() => {
                                props.setSupportSidebarOpen(false);
                              }}
                              className="faq__question"
                            >
                              <Link to={"/support/ticket/" + item.id}>
                                {__("Ticket")}#{item.id}-{item.title}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body className="faq__answer">
                              {item.created_at}
                              <br />
                              <br />
                              {item.body}
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      ))}
                    </>
                  ) : (
                    <div className="single_ticket">
                      {__("No tickets were created yet.")}
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
