import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import DOMPurify from "dompurify";
import { Button, Modal } from "react-bootstrap";
import api from "services/api";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function Messages(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/player/messages", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/message/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  const [fullMessage, setFullMessage] = useState(null);
  const showItem = async (message) => {
    setFullMessage(message);
    var response = await api.post("/player/message/mark-read", {
      id: message.id,
    });
    if (response.status !== 200) {
      props.showAlert(null, response.data.message, true);
    }
    getResults();
  };

  return (
    <>
      <PageHeadline page_name={__("Messages")} />

      <div className="page_content_area">
        <div className="table_area">
          <div className="d-block player_message_container">
            {results.length ? (
              <>
                {results.map((item, index) => (
                  <ul className="notifications-list">
                    {results.map((val, index) => (
                      <li key={index} className="message-item">
                        <div className="message-from">
                          <b>{__("From")}: </b>
                          {val.sender_name} {__("on")} {val.added_time}
                        </div>
                        <h4>
                          {__("Subject")}:{val.message_subject}
                          {!val.message_was_read && (
                            <>
                              <i className="fa fa-envelope unread_mark"></i>
                            </>
                          )}
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              val.message_body.substr(0, 150)
                            ),
                          }}
                        ></p>

                        <Link
                          hrefLang={Translator.getSelectedLanguage().code}
                          to="#"
                          className="open-modal white"
                          onClick={() => showItem(val)}
                        >
                          {__("Read More")}
                        </Link>

                        <Link
                          hrefLang={Translator.getSelectedLanguage().code}
                          to="#"
                          onClick={() => deleteItem(val.id)}
                          className="delete_msg"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}

                <div className="pagination">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
        </div>
      </div>
      {fullMessage && (
        <Modal
          className="modal"
          show={fullMessage}
          size="lg"
          onHide={() => setFullMessage(null)}
        >
          <Modal.Body className="player_message_modal">
            <div onClick={() => setFullMessage(null)} className="modal_close">
              <i className="fal fa-times"></i>
            </div>

            <div className="modal_headline">{__("Message")}</div>
            <div className="message-from">
              <b>{__("From")}: </b>
              {fullMessage.sender_name} {__("on")} {fullMessage.added_time}
            </div>
            <strong>
              {__("Subject")}:{fullMessage.message_subject}
            </strong>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(fullMessage.message_body),
              }}
            ></p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
