import React, { useState, useEffect, useContext, useRef } from "react";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import Translator, { __ } from "services/Translator";
import { Modal, Button } from "react-bootstrap";
import {
  Redirect,
  Route,
  Link,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
// custom component
import LeftSidebar from "elements/LeftSidebar";
import RightSidebar from "elements/RightSidebar";
import CashierModals from "elements/dashboard_elements/CashierModals";
import SupportSidebar from "elements/SupportSidebar";
import LanguageSwitcher from "elements/LanguageSwitcher";
import LoginReg, { loginData } from "elements/LoginReg";
import Logo from "elements/icons/Logo";

import UserDropDownMenu from "elements/UserDropDownMenu";

const Header = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const activeMenu = location.pathname;
  const { updateUserObj } = useContext(AppContext);
  const gamesCategory = params.category;

  const isAuthenticated = props.isAuthenticated;
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  const [notificationCount, setNotifictionCount] = useState(0);

  // userMenu
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const focusSearchBar = () => {
    searchTextBox.current.focus();
  };

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  return (
    <>
      <header>
        <nav class="navbar navbar-expand-lg navbar-light  header_v1">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <Logo />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon">
                <svg
                  width="32"
                  height="26"
                  viewBox="0 0 32 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 5H31"
                    stroke="#27AAE1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M9 13H31"
                    stroke="#27AAE1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M5 21H31"
                    stroke="#27AAE1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </span>

              <span class="navbar-toggler-icon-close">
                <svg
                  width="32"
                  height="26"
                  viewBox="0 0 32 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 2L27.3848 20.3848"
                    stroke="#27AAE1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M9 20L27.3848 1.61522"
                    stroke="#27AAE1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-lg-end navbar_menu_v1"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none mobile_screen_menus">
                <li class="nav-item">
                  <Link
                    className={activeMenu === "/games/all" ? "active" : ""}
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="/games/all"
                  >
                    <span className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2863_12648)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22.7001 7.7999C22.7001 7.9999 22.6001 8.0999 22.4001 8.0999C22.2001 8.0999 22.1001 7.9999 22.1001 7.7999V1.1999C22.1001 0.999902 22.2001 0.899902 22.4001 0.899902C22.6001 0.899902 22.7001 0.999902 22.7001 1.1999V7.7999Z"
                            fill="url(#paint0_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22.7002 7.4998C22.7002 7.8998 22.6002 8.1998 22.5002 8.1998H19.9002C19.8002 8.1998 19.7002 7.8998 19.7002 7.4998C19.7002 7.0998 19.8002 6.7998 19.9002 6.7998H22.5002C22.6002 6.7998 22.7002 7.0998 22.7002 7.4998Z"
                            fill="url(#paint1_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0001 8.2998C21.0001 8.8998 20.5001 9.4998 19.8001 9.4998C19.2001 9.4998 18.6001 8.9998 18.6001 8.2998V6.4998C18.6001 5.8998 19.1001 5.2998 19.8001 5.2998C20.5001 5.2998 21.0001 5.7998 21.0001 6.4998V8.2998Z"
                            fill="url(#paint2_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.7999 1.3C13.7999 2 13.1999 2.6 12.4999 2.6H8.6999C7.9999 2.6 7.3999 2 7.3999 1.3C7.3999 0.6 7.8999 0 8.6999 0H12.4999C13.1999 0 13.7999 0.6 13.7999 1.3Z"
                            fill="url(#paint3_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.1998 3.3999C17.1998 4.1999 16.4998 4.8999 15.6998 4.8999H5.2998C4.4998 4.8999 3.7998 4.1999 3.7998 3.3999C3.7998 2.5999 4.4998 1.8999 5.2998 1.8999H15.6998C16.5998 1.8999 17.1998 2.5999 17.1998 3.3999Z"
                            fill="url(#paint4_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.19971 10.8002V11.7002H9.39971C9.39971 11.7002 9.59971 11.1002 9.99971 11.3002C10.3997 11.5002 10.7997 11.6002 11.1997 11.4002C9.99971 11.9002 9.79971 13.6002 9.79971 13.6002C9.79971 13.6002 10.9997 13.6002 11.3997 13.6002C10.9997 12.0002 12.1997 11.6002 12.1997 10.8002C12.0997 10.8002 11.7997 10.8002 11.7997 10.8002L11.6997 10.9002C10.9997 10.9002 10.7997 10.7002 10.3997 10.7002C9.59971 10.7002 9.39971 11.1002 9.39971 11.0002C9.39971 10.8002 9.39971 10.8002 9.39971 10.8002H9.19971Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.6997 10.8002V11.7002H14.8997C14.8997 11.7002 15.0997 11.1002 15.4997 11.3002C15.8997 11.5002 16.2997 11.6002 16.6997 11.4002C15.4997 11.9002 15.1997 13.6002 15.1997 13.6002C15.1997 13.6002 16.3997 13.6002 16.7997 13.6002C16.3997 12.0002 17.5997 11.6002 17.5997 10.8002C17.4997 10.8002 17.1997 10.8002 17.1997 10.8002L17.0997 10.9002C16.3997 10.9002 16.1997 10.7002 15.7997 10.7002C14.9997 10.7002 14.7997 11.1002 14.7997 11.0002C14.7997 10.8002 14.7997 10.8002 14.7997 10.8002H14.6997Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.7998 10.8002V11.7002H3.9998C3.9998 11.7002 4.1998 11.1002 4.5998 11.3002C4.9998 11.5002 5.3998 11.6002 5.7998 11.4002C4.5998 11.9002 4.2998 13.6002 4.2998 13.6002C4.2998 13.6002 5.4998 13.6002 5.8998 13.6002C5.4998 12.0002 6.6998 11.6002 6.6998 10.8002C6.5998 10.8002 6.2998 10.8002 6.2998 10.8002L6.1998 10.9002C5.4998 10.9002 5.2998 10.7002 4.8998 10.7002C4.0998 10.7002 3.8998 11.1002 3.8998 11.0002C3.8998 10.8002 3.8998 10.8002 3.8998 10.8002H3.7998Z"
                            fill="white"
                          />
                          <path
                            d="M23.2999 0.5H21.3999V3.1H23.2999V0.5Z"
                            fill="url(#paint5_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.7998 3.7998V20.0998H19.4998V3.7998H1.7998ZM7.4998 14.9998H3.1998V9.2998H7.4998V14.9998ZM12.8998 14.9998H8.5998V9.2998H12.8998V14.9998ZM18.2998 14.9998H13.9998V9.2998H18.2998V14.9998ZM18.6998 5.4998H2.1998V4.9998H18.6998V5.4998Z"
                            fill="url(#paint6_radial_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.4 22.8998C18.4 23.4998 17.9 23.9998 17.3 23.9998H4.6C4 23.9998 3.5 23.4998 3.5 22.8998C3.5 22.2998 4 21.7998 4.6 21.7998H17.2C17.9 21.7998 18.4 22.2998 18.4 22.8998Z"
                            fill="url(#paint7_linear_2863_12648)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.1 19H1.8C0.8 19 0 19.8 0 20.8C0 21.8 0.8 22.6 1.8 22.6H19.2C20.2 22.6 21 21.8 21 20.8C20.9 19.8 20.1 19 19.1 19ZM19.3 20.9H2.4C2.2 20.9 2.1 20.8 2.1 20.7C2.1 20.6 2.2 20.5 2.4 20.5H19.3C19.5 20.5 19.6 20.6 19.6 20.7C19.6 20.8 19.4 20.9 19.3 20.9Z"
                            fill="url(#paint8_linear_2863_12648)"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_2863_12648"
                            x1="22.0642"
                            y1="4.51"
                            x2="22.6527"
                            y2="4.51"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_2863_12648"
                            x1="19.5635"
                            y1="7.4519"
                            x2="22.6525"
                            y2="7.4519"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_2863_12648"
                            x1="18.6812"
                            y1="7.3783"
                            x2="21.0347"
                            y2="7.3783"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <linearGradient
                            id="paint3_linear_2863_12648"
                            x1="10.5907"
                            y1="0"
                            x2="10.5907"
                            y2="2.6477"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.000922736" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                          <linearGradient
                            id="paint4_linear_2863_12648"
                            x1="10.5171"
                            y1="1.9121"
                            x2="10.5171"
                            y2="4.8539"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.000922736" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                          <linearGradient
                            id="paint5_linear_2863_12648"
                            x1="21.3723"
                            y1="1.8243"
                            x2="23.2847"
                            y2="1.8243"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <radialGradient
                            id="paint6_radial_2863_12648"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(10.5906 12.0117) scale(8.5012)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </radialGradient>
                          <linearGradient
                            id="paint7_linear_2863_12648"
                            x1="10.9586"
                            y1="21.7934"
                            x2="10.9586"
                            y2="23.9998"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.000472422" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                          <linearGradient
                            id="paint8_linear_2863_12648"
                            x1="10.4436"
                            y1="18.9989"
                            x2="10.4436"
                            y2="22.5291"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.000472422" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                          <clipPath id="clip0_2863_12648">
                            <rect width="23.3" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>{" "}
                    {__("Slots")}
                  </Link>
                </li>

                <li class="nav-item">
                  <Link
                    className={activeMenu === "/promotions" ? "active" : ""}
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="/promotions"
                  >
                    <span className="icon">
                      <svg
                        width="20"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2863_12620)">
                          <path
                            d="M10.2999 10.2002V22.1002L6.2999 20.7002L3.5999 19.7002L0.899902 18.8002V7.2002L3.5999 8.1002L6.2999 8.9002L10.2999 10.2002Z"
                            fill="url(#paint0_radial_2863_12620)"
                          />
                          <path
                            d="M6.8001 11.9003L6.3001 20.7003L5.6001 11.3003L6.8001 11.9003Z"
                            fill="#7C7C7C"
                          />
                          <path
                            d="M10.2998 13.2003V22.1003L19.0998 18.8003V9.80029L10.2998 13.2003Z"
                            fill="url(#paint1_linear_2863_12620)"
                          />
                          <path
                            d="M13.6001 12L14.1001 20.7L14.7001 11.5L13.6001 12Z"
                            fill="#565656"
                          />
                          <path
                            d="M6.2999 8.9002L3.5999 8.1002L0.899902 7.2002V9.8002L10.2999 13.2002V10.2002L6.2999 8.9002Z"
                            fill="#7C7C7C"
                          />
                          <path
                            d="M10.2998 10.2002V13.2002L19.0998 9.8002V7.2002L10.2998 10.2002Z"
                            fill="#565656"
                          />
                          <path
                            d="M0.899902 7.2002L10.2999 10.2002L19.0999 7.2002L9.9999 4.2002L0.899902 7.2002Z"
                            fill="#EA3B3B"
                          />
                          <path
                            d="M10.3 8.60029V12.5003L6.3 11.1003L3.6 10.1003L0 8.80029V5.30029L3.6 6.40029L6.3 7.30029L10.3 8.60029Z"
                            fill="url(#paint2_radial_2863_12620)"
                          />
                          <path
                            d="M19.9998 5.30029V8.80029L16.8998 10.0003L14.0998 11.0003L10.2998 12.5003V8.60029L14.0998 7.30029L16.8998 6.30029L19.9998 5.30029Z"
                            fill="url(#paint3_linear_2863_12620)"
                          />
                          <path
                            d="M0 5.3L10.3 8.6L20 5.3L10 2L0 5.3Z"
                            fill="url(#paint4_linear_2863_12620)"
                          />
                          <path
                            d="M6.3001 7.30039V20.7004L3.6001 19.7004V6.40039L6.3001 7.30039Z"
                            fill="#B5B5B5"
                          />
                          <path
                            d="M14.1001 20.7003V8.90029V7.30029L16.9001 6.30029V8.00029V19.6003L14.1001 20.7003Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M3.6001 6.40039L12.8001 2.90039L15.5001 3.80039L6.3001 7.30039L3.6001 6.40039Z"
                            fill="#C9C9C9"
                          />
                          <path
                            d="M14.1 7.30039L4 3.90039L7.2 2.90039L16.9 6.30039L14.1 7.30039Z"
                            fill="#C9C9C9"
                          />
                          <path
                            d="M9.1998 4.9C8.9998 4.9 8.7998 4.9 8.5998 4.9C7.1998 4.7 5.8998 4.5 4.5998 4.1C3.9998 3.9 3.3998 3.7 2.8998 3.4C2.6998 3.2 2.5998 3 2.4998 2.8C2.3998 2.6 2.3998 2.3 2.4998 2.1C2.5998 1.8 2.9998 1.7 3.3998 1.7C3.9998 1.6 4.4998 1.6 5.0998 1.7C5.5998 1.8 6.0998 2 6.5998 2.1C6.8998 2.2 7.1998 2.3 7.3998 2.5C7.5998 2.6 7.8998 2.8 8.0998 3C8.4998 3.3 8.7998 3.7 9.0998 4C9.1998 4.1 9.4998 4.3 9.4998 4.5C9.5998 4.8 9.2998 4.9 9.1998 4.9Z"
                            fill="#B5B5B5"
                          />
                          <path
                            d="M9.09972 4.60024C8.99972 4.60024 8.89971 4.60024 8.89971 4.60024C7.59971 4.30024 6.49972 3.40024 5.29972 3.00024C4.59972 2.80024 3.89972 2.70024 3.19972 2.50024C2.99972 2.50024 2.79972 2.30024 2.79972 2.10024C2.69972 2.00024 2.89972 1.80024 2.99972 1.70024C3.49972 1.40024 4.19972 1.40024 4.79972 1.50024C6.09972 1.70024 7.39971 2.30024 8.39971 3.20024C8.59971 3.40024 8.79972 3.60024 8.99972 3.90024C9.09972 4.00024 9.29972 4.40024 9.09972 4.60024Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M6.4999 0.900195C6.0999 0.600195 5.6999 0.300195 5.1999 0.100195C4.6999 -0.0998047 4.1999 -0.0998047 3.6999 0.100195C3.2999 0.300195 2.9999 0.600195 2.7999 1.0002C2.5999 1.4002 2.3999 1.8002 2.3999 2.4002C2.9999 1.3002 5.7999 2.4002 7.2999 3.1002C8.0999 3.5002 8.8999 4.1002 9.4999 4.9002C9.4999 5.0002 9.7999 4.6002 9.7999 4.6002C9.8999 4.4002 9.7999 4.4002 9.6999 4.2002C9.3999 3.9002 9.1999 3.6002 8.9999 3.3002C8.4999 2.8002 7.9999 2.2002 7.4999 1.7002C7.1999 1.4002 6.7999 1.2002 6.4999 0.900195Z"
                            fill="#C9C9C9"
                          />
                          <path
                            d="M10.7999 5.00049C10.9999 5.00049 11.1999 5.00049 11.3999 5.00049C12.6999 4.80049 13.9999 4.50049 15.2999 4.20049C15.8999 4.00049 16.4999 3.70049 16.9999 3.40049C17.1999 3.30049 17.2999 3.10049 17.3999 2.90049C17.4999 2.70049 17.4999 2.40049 17.3999 2.20049C17.1999 1.90049 16.7999 1.80049 16.4999 1.70049C15.8999 1.60049 15.3999 1.60049 14.7999 1.70049C14.2999 1.80049 13.7999 2.00049 13.2999 2.20049C12.9999 2.30049 12.6999 2.40049 12.4999 2.60049C12.2999 2.70049 12.0999 2.90049 11.7999 3.10049C11.3999 3.40049 11.0999 3.80049 10.7999 4.20049C10.6999 4.30049 10.3999 4.60049 10.3999 4.70049C10.3999 4.90049 10.5999 4.90049 10.7999 5.00049Z"
                            fill="#B5B5B5"
                          />
                          <path
                            d="M10.8002 4.60024C10.9002 4.60024 11.0002 4.60024 11.0002 4.60024C12.3002 4.30024 13.3002 3.40024 14.6002 3.00024C15.3002 2.80024 16.0002 2.70024 16.7002 2.50024C16.9002 2.40024 17.1002 2.30024 17.1002 2.10024C17.1002 1.90024 17.0002 1.80024 16.8002 1.70024C16.3002 1.40024 15.6002 1.40024 15.0002 1.50024C13.7002 1.70024 12.4002 2.40024 11.4002 3.30024C11.2002 3.50024 11.0002 3.70024 10.9002 3.90024C10.9002 4.10024 10.6002 4.50024 10.8002 4.60024Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M13.4001 1.00029C13.8001 0.700293 14.2001 0.400293 14.7001 0.200293C15.2001 0.000292972 15.7001 0.000292972 16.2001 0.200293C16.6001 0.400293 16.9001 0.700293 17.1001 1.10029C17.3001 1.50029 17.6001 1.80029 17.5001 2.50029C16.9001 1.40029 14.1001 2.50029 12.7001 3.30029C11.9001 3.70029 11.1001 4.30029 10.5001 5.10029C10.5001 5.20029 10.2001 4.80029 10.2001 4.80029C10.1001 4.60029 10.2001 4.60029 10.3001 4.40029C10.5001 4.00029 10.7001 3.70029 11.0001 3.40029C11.5001 2.80029 12.0001 2.30029 12.5001 1.80029C12.8001 1.50029 13.1001 1.20029 13.4001 1.00029Z"
                            fill="#C9C9C9"
                          />
                          <path
                            d="M9.1002 3.60014C8.9002 3.70014 8.8002 3.80014 8.7002 4.00014C8.7002 4.10014 8.7002 4.20014 8.7002 4.30014C8.7002 4.50014 8.7002 4.60014 8.8002 4.80014C8.8002 4.90014 8.8002 5.00014 8.9002 5.00014C9.0002 5.20014 9.1002 5.30014 9.2002 5.30014C9.7002 5.50014 10.2002 5.50014 10.7002 5.40014C10.9002 5.40014 11.1002 5.30014 11.2002 5.10014C11.2002 5.00014 11.2002 4.90014 11.2002 4.90014C11.2002 4.70014 11.2002 4.50014 11.1002 4.20014C11.0002 4.00014 10.9002 3.80014 10.8002 3.60014C10.6002 3.50014 10.4002 3.40014 10.2002 3.40014C9.8002 3.30014 9.4002 3.30014 9.1002 3.60014Z"
                            fill="#B5B5B5"
                          />
                          <path
                            d="M11.1003 4.2C11.0003 4 10.9003 3.8 10.8003 3.6L10.7003 3.5C10.8003 3.6 10.8003 3.7 10.8003 3.8C10.9003 4 10.9003 4.2 10.9003 4.5C10.9003 4.6 10.9003 4.7 10.9003 4.7C10.8003 4.9 10.6003 5 10.4003 5C9.90029 5.1 9.40029 5.1 8.90029 4.9C8.90029 4.9 8.90029 4.9 8.80029 4.9C8.80029 5 8.80029 5 8.90029 5.1C9.00029 5.2 9.10029 5.3 9.20029 5.3C9.70029 5.5 10.2003 5.5 10.7003 5.4C10.9003 5.4 11.1003 5.3 11.2003 5.1C11.2003 5 11.2003 4.9 11.2003 4.9C11.2003 4.6 11.2003 4.4 11.1003 4.2Z"
                            fill="#8C8C8C"
                          />
                          <path
                            opacity="0.31"
                            d="M9.1 3.80033C9 4.00033 9 4.20033 9 4.40033V4.50033C9 4.60033 9.1 4.70033 9.2 4.70033C9.3 4.70033 9.3 4.70033 9.4 4.70033C9.5 4.60033 9.5 4.50033 9.5 4.40033C9.5 4.20033 9.7 4.00033 9.9 4.00033C10 4.00033 10 4.00033 10.1 4.00033C10.2 4.00033 10.2 3.90033 10.2 3.90033C10.2 3.70033 9.8 3.70033 9.7 3.70033C9.5 3.50033 9.3 3.60033 9.1 3.80033Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <radialGradient
                            id="paint0_radial_2863_12620"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(5.6079 14.6669) scale(6.2223)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </radialGradient>
                          <linearGradient
                            id="paint1_linear_2863_12620"
                            x1="10.3132"
                            y1="15.9496"
                            x2="19.0976"
                            y2="15.9496"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.000472422" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                          <radialGradient
                            id="paint2_radial_2863_12620"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(5.1719 8.87589) scale(4.4584)"
                          >
                            <stop stop-color="#F0F0F0" />
                            <stop offset="1" stop-color="#C8C8C8" />
                          </radialGradient>
                          <linearGradient
                            id="paint3_linear_2863_12620"
                            x1="10.3433"
                            y1="8.87589"
                            x2="19.9998"
                            y2="8.87589"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.00336366" stop-color="#BEBEBE" />
                            <stop offset="1" stop-color="#F0F0F0" />
                          </linearGradient>
                          <linearGradient
                            id="paint4_linear_2863_12620"
                            x1="0"
                            y1="5.2536"
                            x2="20"
                            y2="5.2536"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <clipPath id="clip0_2863_12620">
                            <rect width="20" height="22.1" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {__("Promotions")}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      activeMenu === "/games/live-casino" ? "active" : ""
                    }
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="/games/live-casino"
                  >
                    <span className="icon">
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2863_12598)">
                          <path
                            d="M8.1002 16.7001C10.3002 16.5001 12.4002 10.5001 10.8002 9.60011C9.2002 8.70011 3.5002 9.30011 4.1002 10.8001C4.7002 12.3001 5.4002 17.0001 8.1002 16.7001Z"
                            fill="url(#paint0_radial_2863_12598)"
                          />
                          <path
                            d="M14.8998 11.5001L14.7998 11.4001L14.6998 11.3001C14.5998 11.3001 14.5998 11.2001 14.4998 11.2001C14.3998 11.2001 14.3998 11.1001 14.2998 11.1001C14.1998 11.1001 14.1998 11.0001 14.0998 11.0001C13.9998 11.0001 13.9998 10.9001 13.8998 10.9001C13.7998 10.9001 13.7998 10.8001 13.6998 10.8001C13.5998 10.8001 13.5998 10.7001 13.4998 10.7001C13.3998 10.7001 13.2998 10.6001 13.2998 10.6001C13.1998 10.6001 13.1998 10.5001 13.0998 10.5001C13.0998 10.5001 13.0998 10.5001 12.9998 10.5001C12.8998 10.5001 12.8998 10.4001 12.7998 10.4001H12.6998C12.5998 10.4001 12.5998 10.4001 12.4998 10.3001C12.3998 10.3001 12.3998 10.3001 12.2998 10.2001C12.2998 10.2001 12.2998 10.2001 12.1998 10.2001C11.8998 10.1001 11.5998 10.0001 11.3998 9.9001C11.1998 9.8001 10.9998 9.8001 10.8998 9.7001C11.0998 10.0001 11.1998 10.2001 11.1998 10.5001C12.1998 13.7001 7.99985 16.7001 7.99985 16.7001C4.99985 14.3001 4.89985 12.4001 5.19985 11.1001C4.99985 10.3001 5.09985 9.8001 5.29985 9.6001C5.19985 9.6001 4.89985 9.7001 4.69985 9.8001C4.59985 9.8001 4.49985 9.9001 4.49985 9.9001C4.29985 9.9001 4.09985 10.0001 3.99985 10.1001C3.79985 10.2001 3.69985 10.2001 3.49985 10.3001C3.39985 10.3001 3.29985 10.4001 3.29985 10.4001C3.19985 10.4001 3.09985 10.5001 3.09985 10.5001C2.99985 10.5001 2.89985 10.6001 2.89985 10.6001C2.79985 10.7001 2.59985 10.7001 2.49985 10.8001C2.39985 10.8001 2.39985 10.9001 2.29985 10.9001C1.89985 11.0001 1.89985 11.0001 1.79985 11.0001C1.69985 11.0001 1.69985 11.1001 1.59985 11.1001L1.49985 11.2001L1.39985 11.3001L1.29985 11.4001C0.899847 11.8001 0.499847 12.4001 0.299847 13.1001C-0.000153303 14.1001 -0.000153318 14.8001 0.0998467 15.5001C2.19985 17.9001 5.19985 19.6001 8.59985 19.4001C12.1998 19.2001 14.9998 17.2001 15.9998 15.5001C16.0998 12.9001 15.7998 12.4001 14.8998 11.5001Z"
                            fill="url(#paint1_linear_2863_12598)"
                          />
                          <path
                            d="M7.2999 11.3999C7.3999 11.4999 7.6999 11.5999 7.8999 11.6999C7.9999 11.6999 7.9999 11.6999 8.0999 11.6999C8.2999 11.5999 8.4999 11.4999 8.6999 11.4999C9.5999 10.8999 9.7999 10.4999 10.5999 9.5999C10.5999 9.4999 10.6999 7.9999 10.5999 8.7999C9.7999 9.5999 8.9999 9.8999 8.0999 9.9999H7.9999H7.8999C6.9999 9.8999 6.1999 9.5999 5.3999 8.6999C5.3999 8.8999 5.3999 9.1999 5.3999 9.4999C5.9999 10.2999 6.6999 10.9999 7.2999 11.3999Z"
                            fill="#B5B5B5"
                          />
                          <path
                            d="M7.8 9.99994H7.9H8C9 9.89993 9.8 9.59994 10.6 8.59994C11 8.09994 11.3 7.49994 11.6 6.59994C12.1 6.39994 12.4 5.59994 12.4 5.29994C12.4 4.99994 12.4 4.69993 12 4.49993C11.9 4.79993 11.7 5.69993 11.7 5.69993L11.8 4.59993C11.9 2.19993 10.6 0.999935 8.6 2.09993C8.5 2.09993 8.2 2.09993 8 2.09993C7.8 2.09993 7.6 2.09994 7.4 1.99994C5.4 0.899935 4.1 2.09993 4.2 4.49993L4.3 5.59994C4.3 5.59994 4.1 4.69993 4 4.39994C4 3.99993 4 3.59994 4 3.19994C3.9 3.69993 3.8 4.09993 3.9 4.49993C3.5 4.59993 3.5 4.99994 3.5 5.29994C3.5 5.59994 3.8 6.39994 4.3 6.59994C4.7 7.49994 5 8.19993 5.4 8.69993C6.2 9.59993 7 9.89993 7.8 9.99994Z"
                            fill="url(#paint2_linear_2863_12598)"
                          />
                          <path
                            d="M10.7997 11.9C10.7997 11.9 10.7997 12 10.7997 12.1C11.0997 11.4 11.0997 10.9 10.9997 10.4C10.8997 10.1 10.7997 9.9 10.6997 9.6C10.6997 9.6 10.6997 9.6 10.6997 9.5C10.9997 10.1 11.1997 10.7 10.7997 11.9Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M5.3 9.6C5.1 9.8 5 10.3 5 10.9C5 11.3 5.2 11.8 5.4 12.4C5.4 12.3 5.4 12.3 5.4 12.2C4.9 10.8 5.1 9.9 5.3 9.6Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M6 12.9C6 12.9 6.9 12.6 7.4 12.6V12.5L6 12.9Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M6.4998 11.6997C6.9998 11.9997 7.0998 12.0997 7.0998 12.0997C7.0998 12.0997 6.5998 12.0997 5.7998 12.1997C6.4998 12.1997 6.9998 12.2997 7.2998 12.2997C7.2998 12.1997 7.2998 12.0997 7.2998 12.0997C7.1998 11.8997 6.8998 11.7997 6.4998 11.6997Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M7.3999 12.7V12.6C6.8999 12.6 5.9999 12.9 5.9999 12.9L7.2999 12.4C7.2999 12.3 7.2999 12.3 7.2999 12.2C7.0999 12.2 6.4999 12.2 5.7999 12.1C6.5999 11.9 7.0999 12 7.0999 12C7.0999 12 6.9999 12 6.4999 11.7C6.8999 11.8 7.1999 11.9 7.2999 12C7.2999 11.9 7.2999 11.8 7.2999 11.8C7.0999 11.6 6.4999 11.1 5.5999 11C5.5999 11.5 5.3999 11.9 5.3999 12.5C5.3999 13 5.5999 13.2 5.6999 13.6C6.5999 13.2 6.9999 13.2 7.2999 12.8V12.7C7.3999 12.8 7.3999 12.8 7.3999 12.7Z"
                            fill="url(#paint3_linear_2863_12598)"
                          />
                          <path
                            d="M9.49971 12.6999C9.69971 12.6999 10.3997 12.6999 10.3997 12.6999C10.3997 12.6999 9.39971 12.5999 8.69971 12.3999V12.4999C8.89971 12.5999 9.29971 12.5999 9.49971 12.6999Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M9.19971 12.1C8.99971 12 8.79971 12.1 8.79971 12.1C9.59971 11.8 9.59971 12.2 9.79971 12C9.89971 12 9.79971 11.8 9.69971 11.8C9.49971 11.7 8.89971 11.9 8.69971 12.1V12.2C8.69971 12.1 8.99971 12.1 9.19971 12.1Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M8.69971 12.1997C8.69971 12.2997 8.69971 12.2997 8.69971 12.1997C8.79971 12.2997 8.89971 12.1997 8.89971 12.1997C8.79971 12.2997 8.69971 12.2997 8.69971 12.1997Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M8.7 12.7001L8.8 12.8001C9 13.2001 9.8 13.6001 10.2 13.7001C10.4 13.3001 10.7 13.1001 10.7 12.5001C10.7 11.7001 10.6 11.5001 10.5 11.1001C9.2 11.2001 8.7 11.6001 8.5 11.8001C8.5 11.9001 8.5 12.0001 8.6 12.0001C8.9 11.9001 9.4 11.6001 9.6 11.7001C9.7 11.7001 9.8 11.9001 9.7 11.9001C9.5 12.1001 9.4 11.8001 8.7 12.0001C8.7 12.0001 8.9 12.0001 9.2 12.1001C9 12.0001 8.7 12.1001 8.6 12.1001V12.2001C8.7 12.2001 8.8 12.2001 8.8 12.2001C8.8 12.2001 8.7 12.2001 8.6 12.3001V12.4001C9.3 12.5001 10.3 12.7001 10.3 12.7001C10.3 12.7001 9.7 12.7001 9.4 12.7001C9.2 12.7001 8.8 12.6001 8.6 12.6001V12.7001C8.6 12.6001 8.7 12.7001 8.7 12.7001Z"
                            fill="url(#paint4_linear_2863_12598)"
                          />
                          <path
                            d="M8.59975 11.8C8.69975 12.1 8.59975 12.6 8.59975 12.7C8.59975 12.7 7.59975 12.8 7.49975 12.7C7.29975 12.3 7.39975 11.8 7.49975 11.8C7.49975 11.7 8.39975 11.5 8.59975 11.8Z"
                            fill="#6F6B82"
                          />
                          <path
                            d="M7.49975 12.5999C7.59975 12.6999 8.59975 12.5999 8.59975 12.5999C8.59975 12.5999 8.69975 11.9999 8.59975 11.6999C8.49975 11.4999 7.59975 11.5999 7.49975 11.6999C7.39975 11.7999 7.29975 12.1999 7.49975 12.5999Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M7.9 13.2998C7.7 13.2998 7.5 13.4998 7.5 13.6998C7.5 13.8998 7.7 14.0998 7.9 14.0998C8.1 14.0998 8.3 13.8998 8.3 13.6998C8.3 13.4998 8.1 13.2998 7.9 13.2998Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M7.9 14.8999C7.7 14.8999 7.5 15.0999 7.5 15.2999C7.5 15.4999 7.7 15.6999 7.9 15.6999C8.1 15.6999 8.3 15.4999 8.3 15.2999C8.3 15.0999 8.1 14.8999 7.9 14.8999Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M3.9999 4.4999C3.9999 4.7999 4.1999 5.5999 4.1999 5.5999V4.4999C4.0999 2.0999 5.3999 0.899902 7.3999 1.9999C7.5999 2.0999 7.7999 2.0999 7.9999 2.0999C8.1999 2.0999 8.3999 1.9999 8.5999 1.9999C10.5999 0.899902 11.8999 2.0999 11.7999 4.4999L11.6999 5.5999C11.6999 5.5999 11.8999 4.6999 11.9999 4.3999C11.9999 4.2999 11.9999 4.0999 11.9999 3.9999C12.0999 1.3999 10.5999 0.0999023 8.0999 -0.100098L7.9999 -9.76548e-05H7.7999C5.5999 0.0999023 4.1999 1.1999 3.8999 3.2999C3.8999 3.6999 3.8999 4.0999 3.9999 4.4999Z"
                            fill="#8C8C8C"
                          />
                          <path
                            d="M8 6.1001C6.9 6.1001 6 6.8001 6 7.7001C6 8.6001 6.9 9.3001 8 9.3001C9.1 9.3001 10 8.6001 10 7.7001C10 6.8001 9.1 6.1001 8 6.1001ZM9 8.7001C8.8 8.7001 8.3 8.2001 8.2 8.2001C8.1 8.2001 8.1 8.2001 8 8.2001C7.9 8.2001 7.8 8.2001 7.8 8.2001C7.6 8.2001 7.2 8.7001 7 8.7001C6.7 8.8001 6.4 8.5001 6.3 8.1001C6.2 7.8001 6.2 7.6001 6.3 7.4001C6.4 7.1001 7.2 6.8001 8 6.8001C8.9 6.8001 9.6 7.1001 9.7 7.4001C9.8 7.6001 9.8 7.8001 9.7 8.0001C9.6 8.5001 9.3 8.8001 9 8.7001Z"
                            fill="#8C8C8C"
                          />
                        </g>
                        <defs>
                          <radialGradient
                            id="paint0_radial_2863_12598"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(7.7235 12.9456) scale(3.7291)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" />
                          </radialGradient>
                          <linearGradient
                            id="paint1_linear_2863_12598"
                            x1="8.00035"
                            y1="19.4225"
                            x2="8.00035"
                            y2="9.505"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#BEBEBE" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_2863_12598"
                            x1="3.4996"
                            y1="5.78934"
                            x2="12.4477"
                            y2="5.78934"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <linearGradient
                            id="paint3_linear_2863_12598"
                            x1="5.4547"
                            y1="12.3678"
                            x2="7.4394"
                            y2="12.3678"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.00336366" stop-color="#BEBEBE" />
                            <stop offset="1" stop-color="#F0F0F0" />
                          </linearGradient>
                          <linearGradient
                            id="paint4_linear_2863_12598"
                            x1="8.6021"
                            y1="12.4211"
                            x2="10.7479"
                            y2="12.4211"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.00336366" stop-color="#BEBEBE" />
                            <stop offset="1" stop-color="#F0F0F0" />
                          </linearGradient>
                          <clipPath id="clip0_2863_12598">
                            <rect width="16" height="19.4" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>{" "}
                    {__("Live")}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={activeMenu === "/races" ? "active" : ""}
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="/races"
                  >
                    <span className="icon">
                      <svg
                        width="20"
                        height="24"
                        viewBox="0 0 20 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 11.3C0 11.3 3.9 10.1 5.9 11.9L13.9 3.49997C13.9 3.49997 11.8 -1.10003 5 0.199971C5 0.299971 1.3 6.09997 0 11.3Z"
                          fill="url(#paint0_linear_2863_12560)"
                        />
                        <path
                          d="M15.0998 2.2999L5.2998 12.6999C5.2998 12.6999 12.7998 14.4999 14.7998 17.7999L19.4998 4.9999C19.4998 4.9999 18.0998 1.9999 15.0998 2.2999Z"
                          fill="url(#paint1_linear_2863_12560)"
                        />
                        <path
                          d="M19.4 4.40001L13 22.9C13 22.9 13.2 23.4 13.6 23.1L20 4.70001C20 4.70001 20 4.20001 19.4 4.40001Z"
                          fill="url(#paint2_linear_2863_12560)"
                        />
                        <path
                          d="M0.7 8.99995C0.7 8.99995 3.1 8.69995 3.4 8.79995C3.4 8.79995 2.8 10.3999 2.7 10.8999L0 11.2999C0 11.2999 0.2 9.69995 0.7 8.99995Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M1.6001 6.70005L4.2001 6.60005L5.1001 4.30005H2.7001L1.6001 6.70005Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M3.7002 2.29995L6.3002 2.19995L7.4002 -4.88274e-05C7.4002 -4.88274e-05 6.0002 -0.200049 5.0002 0.199951L3.7002 2.29995Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M4.1999 6.59985L3.3999 8.89985L5.6999 9.39985L6.4999 6.89985L4.1999 6.59985Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M5 11.3999L5.7 9.3999L7.7 10.1999L5.9 11.9999C5.9 11.9999 5.2 11.3999 5 11.3999Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M5.1001 4.29997C5.1001 4.29997 7.2001 4.39997 7.5001 4.49997L8.7001 2.19997C8.7001 2.19997 6.9001 1.99997 6.3001 2.19997L5.1001 4.29997Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M9.7002 0.300049L8.7002 2.30005C8.7002 2.30005 10.7002 3.00005 10.8002 3.30005L11.7002 1.30005C11.6002 1.20005 10.9002 0.600049 9.7002 0.300049Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M7.5 4.5C7.5 4.5 9.2 4.9 9.6 5.4L8.6 7.9C8.6 7.9 7 6.9 6.5 6.9L7.5 4.5Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M10.8001 3.19995L9.6001 5.49995C9.6001 5.49995 10.9001 6.59995 11.0001 6.79995L12.5001 5.19995C12.5001 5.09995 11.0001 3.39995 10.8001 3.19995Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M7.7998 10L8.6998 7.80005C8.6998 7.80005 9.3998 8.10005 9.3998 8.30005L7.7998 10Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M11.1001 6.5999H11.7001L13.3001 4.4999L13.1001 4.3999L11.1001 6.5999Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M12.2998 5.10005L13.3998 2.80005L13.8998 3.60005L12.4998 5.10005H12.2998Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M9.2001 8.59985L10.8001 9.09985C10.8001 9.09985 10.4001 11.2999 10.4001 11.5999L8.1001 10.8999L8.5001 9.29985L9.2001 8.59985Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M7.2998 10.5999L8.1998 10.8999L6.9998 13.0999L5.2998 12.6999L7.2998 10.5999Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M9.2998 13.9C9.2998 13.9 10.3998 12 10.4998 11.5L12.6998 12.6L11.6998 15C11.5998 15.1 9.6998 14 9.2998 13.9Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M10.7998 9.10003L12.9998 9.70003C12.9998 9.70003 13.6998 7.10003 13.9998 7.00003L11.7998 6.70003C11.6998 6.60003 10.8998 8.20003 10.7998 9.10003Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M13.0002 9.69995L12.7002 12.7L14.3002 13.7L15.0002 10.8L13.0002 9.69995Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M13.5 16.4L14.3 13.7L15.8 14.9L14.8 17.7L13.5 16.4Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M14 6.9L16 8L17.3 5.4C17.3 5.4 15.9 4.5 15.5 4.5L14 6.9Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M13.1001 4.39995L15.4001 4.49995L16.6001 2.39995C16.6001 2.39995 15.7001 2.09995 15.1001 2.29995L13.1001 4.39995Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M15 10.7999L16.7 12.4999L17.6 9.99985L16 8.09985L15 10.7999Z"
                          fill="#AAAAAA"
                        />
                        <path
                          d="M18.1002 3.19995L17.2002 5.39995C17.2002 5.39995 18.5002 7.29995 18.5002 7.49995L19.5002 4.89995C19.5002 4.89995 18.5002 3.29995 18.1002 3.19995Z"
                          fill="#AAAAAA"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2863_12560"
                            x1="2.40412"
                            y1="4.46886"
                            x2="10.1254"
                            y2="8.48843"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_2863_12560"
                            x1="10.3381"
                            y1="7.6133"
                            x2="18.2821"
                            y2="11.2571"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_2863_12560"
                            x1="16.1959"
                            y1="13.6842"
                            x2="17.1068"
                            y2="13.9971"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#B4B4B4" />
                            <stop offset="0.5056" stop-color="#DCDCDC" />
                            <stop offset="1" stop-color="#B4B4B4" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>{" "}
                    {__("Races")}
                  </Link>
                </li>

                <li class="nav-item">
                  <Link
                    className={activeMenu === "/vip" ? "active" : ""}
                    hrefLang={Translator.getSelectedLanguage().code}
                    to="/vip"
                  >
                    <span className="icon">
                      <svg
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2863_12587)">
                          <path
                            d="M4.9 6H0L9.7 16.8L4.9 6Z"
                            fill="url(#paint0_linear_2863_12587)"
                          />
                          <path
                            d="M15.2 6L10.5 16.8L20 6H15.2Z"
                            fill="url(#paint1_linear_2863_12587)"
                          />
                          <path
                            d="M5.2998 6L10.0998 16.8L14.7998 6H5.2998Z"
                            fill="url(#paint2_linear_2863_12587)"
                          />
                          <path
                            d="M5.2998 5.6001H14.7998L9.9998 0.600098L5.2998 5.6001Z"
                            fill="url(#paint3_radial_2863_12587)"
                          />
                          <path
                            d="M16.9002 0.600098L15.2002 5.6001H20.0002L16.9002 0.600098Z"
                            fill="url(#paint4_radial_2863_12587)"
                          />
                          <path
                            d="M0 5.6001H4.9L3.1 0.600098L0 5.6001Z"
                            fill="url(#paint5_radial_2863_12587)"
                          />
                          <path
                            d="M3.3999 0L5.2999 5.1L9.9999 0H3.3999Z"
                            fill="url(#paint6_linear_2863_12587)"
                          />
                          <path
                            d="M10 0L14.8 5.1L16.5 0H10Z"
                            fill="url(#paint7_linear_2863_12587)"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_2863_12587"
                            x1="3.5486"
                            y1="12.1428"
                            x2="7.2231"
                            y2="10.0213"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#969696" />
                            <stop offset="0.5056" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="#9696A0" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_2863_12587"
                            x1="12.8817"
                            y1="10.0173"
                            x2="16.4916"
                            y2="12.1015"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#969696" />
                            <stop offset="0.5056" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="#9696A0" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_2863_12587"
                            x1="5.2736"
                            y1="11.3783"
                            x2="14.8288"
                            y2="11.3783"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#969696" />
                            <stop offset="0.5056" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="#9696A0" />
                          </linearGradient>
                          <radialGradient
                            id="paint3_radial_2863_12587"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(10.0505 3.09228) scale(3.82282)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </radialGradient>
                          <radialGradient
                            id="paint4_radial_2863_12587"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(17.593 3.09228) scale(2.46146)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </radialGradient>
                          <radialGradient
                            id="paint5_radial_2863_12587"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(2.44916 3.09228) scale(2.49034)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </radialGradient>
                          <linearGradient
                            id="paint6_linear_2863_12587"
                            x1="3.4461"
                            y1="2.5306"
                            x2="10.0498"
                            y2="2.5306"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#969696" />
                            <stop offset="0.5056" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="#9696A0" />
                          </linearGradient>
                          <linearGradient
                            id="paint7_linear_2863_12587"
                            x1="10.0499"
                            y1="2.5306"
                            x2="16.5381"
                            y2="2.5306"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#969696" />
                            <stop offset="0.5056" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="#9696A0" />
                          </linearGradient>
                          <clipPath id="clip0_2863_12587">
                            <rect width="20" height="16.8" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {__("VIP Lounge")}
                  </Link>
                </li>

                <li class="nav-item">
                  <Link
                    hrefLang={Translator.getSelectedLanguage().code}
                    onClick={() => {
                      props.setSupportSidebarOpen(true);
                    }}
                  >
                    <span className="icon">
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2863_12554)">
                          <path
                            d="M18.8182 8C18.3636 3.54545 14.6364 0 10 0C5.36364 0 1.63636 3.54545 1.18182 8C0.545455 8.09091 0 8.63636 0 9.36364V12.7273C0 13.4545 0.545455 14 1.27273 14H2.27273C2.45455 14 2.54545 14 2.72727 13.9091C2.72727 13.7273 2.81818 13.5455 2.90909 13.3636C3.09091 13.1818 3.27273 13 3.54545 12.9091C3.54545 12.8182 3.54545 12.8182 3.54545 12.7273V9.36364C3.54545 8.72727 3.09091 8.27273 2.54545 8.09091C3.63636 5.09091 6.54545 3 10 3C13.4545 3 16.3636 5.09091 17.4545 8.09091C16.9091 8.18182 16.4545 8.72727 16.4545 9.27273V12.7273C16.4545 13.4545 17 14 17.7273 14H18.8182C19.5455 14 20.0909 13.4545 20.0909 12.7273V9.27273C20 8.63636 19.4545 8.09091 18.8182 8ZM10 2.36364C7 2.36364 4.36364 3.90909 2.81818 6.18182C3.90909 3.27273 6.72727 1.18182 10 1.18182C13.2727 1.18182 16.0909 3.27273 17.1818 6.18182C15.6364 3.90909 13 2.36364 10 2.36364Z"
                            fill="url(#paint0_radial_2863_12554)"
                          />
                          <path
                            d="M7.99993 16.1819L4.27266 13.4546C3.99993 13.2728 3.54539 13.2728 3.36357 13.6365C3.18175 13.9092 3.18175 14.3637 3.54539 14.5455L7.54539 17.4546V17.3637V17.2728C7.45448 16.9092 7.7272 16.4546 7.99993 16.1819Z"
                            fill="url(#paint1_linear_2863_12554)"
                          />
                          <path
                            d="M10.2727 16.3635H8.90909C8.36364 16.3635 8 16.8181 8 17.2726V17.3635C8 17.909 8.45455 18.2726 8.90909 18.2726H10.2727C10.8182 18.2726 11.1818 17.8181 11.1818 17.3635V17.2726C11.2727 16.8181 10.8182 16.3635 10.2727 16.3635Z"
                            fill="url(#paint2_linear_2863_12554)"
                          />
                        </g>
                        <defs>
                          <radialGradient
                            id="paint0_radial_2863_12554"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(10 7.00082) scale(8.63164 8.63164)"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </radialGradient>
                          <linearGradient
                            id="paint1_linear_2863_12554"
                            x1="3.19775"
                            y1="15.4309"
                            x2="8.00993"
                            y2="15.4309"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.000922736" stop-color="#D2D2D2" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_2863_12554"
                            x1="8.01055"
                            y1="17.3693"
                            x2="11.2538"
                            y2="17.3693"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#DCDCDC" />
                            <stop offset="0.5051" stop-color="white" />
                            <stop offset="1" stop-color="#DCDCDC" />
                          </linearGradient>
                          <clipPath id="clip0_2863_12554">
                            <rect width="20" height="18.3636" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {__("Help")}
                  </Link>
                </li>
              </ul>

              <ul class="navbar-nav right_menus">
                <li className="theme_mode d-block d-lg-none">
                  <ul>
                    <li
                      onClick={() => {
                        ls.set("themeMode", "light");
                        props.setTheme("light");
                        // window.location.reload(false);
                      }}
                      className={props.theme === "light" ? "active" : ""}
                    >
                      <span className="icon">
                        <img
                          src={
                            require("assets/images/v1/icons/light-mode.png")
                              .default
                          }
                        />
                      </span>
                      {__("Light mode")}
                    </li>
                    <li
                      onClick={() => {
                        ls.set("themeMode", "dark");
                        props.setTheme("dark");
                        // window.location.reload(false);
                      }}
                      className={
                        props.theme === "dark" ? "active dark" : "dark"
                      }
                    >
                      {__("Dark mode")}
                      <span className="icon">
                        <img
                          src={
                            require("assets/images/v1/icons/dark-mode.png")
                              .default
                          }
                        />
                      </span>
                    </li>
                  </ul>
                </li>

                <li class="nav-item mobile_screen_full_width">
                  <div class="input-group searchbox">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Search"
                      ref={searchTextBox}
                    />
                    <div onClick={focusSearchBar} class="input-group-append">
                      <span class="input-group-text">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.42857 0C9.39875 0 11.2882 0.782651 12.6814 2.17578C14.0745 3.56891 14.8571 5.45839 14.8571 7.42857C14.8571 9.26857 14.1829 10.96 13.0743 12.2629L13.3829 12.5714H14.2857L20 18.2857L18.2857 20L12.5714 14.2857V13.3829L12.2629 13.0743C10.96 14.1829 9.26857 14.8571 7.42857 14.8571C5.45839 14.8571 3.56891 14.0745 2.17578 12.6814C0.782651 11.2882 0 9.39875 0 7.42857C0 5.45839 0.782651 3.56891 2.17578 2.17578C3.56891 0.782651 5.45839 0 7.42857 0ZM7.42857 2.28571C4.57143 2.28571 2.28571 4.57143 2.28571 7.42857C2.28571 10.2857 4.57143 12.5714 7.42857 12.5714C10.2857 12.5714 12.5714 10.2857 12.5714 7.42857C12.5714 4.57143 10.2857 2.28571 7.42857 2.28571Z"
                            fill="#666666"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </li>

                <li className="d-none d-lg-block">
                  <Link
                    hrefLang={Translator.getSelectedLanguage().code}
                    onClick={() => {
                      props.setSupportSidebarOpen(true);
                    }}
                    className="supportLink"
                  >
                    <svg
                      width="11"
                      height="20"
                      viewBox="0 0 11 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.45826 12.952H2.46126L2.38026 8.713C5.42226 8.713 6.94326 7.759 6.94326 5.851C6.94326 5.095 6.70926 4.492 6.24126 4.042C5.77326 3.592 5.08026 3.367 4.16226 3.367C2.90226 3.367 1.65126 3.772 0.409262 4.582V1.477C1.54326 0.882999 2.93826 0.585999 4.59426 0.585999C6.28626 0.585999 7.65426 1.027 8.69826 1.909C9.74226 2.791 10.2643 3.997 10.2643 5.527C10.2643 6.337 10.1023 7.066 9.77826 7.714C9.45426 8.362 9.04026 8.875 8.53626 9.253C8.05026 9.631 7.54626 9.919 7.02426 10.117C6.50226 10.315 5.99826 10.432 5.51226 10.468L5.45826 12.952ZM6.05226 17.083C6.05226 17.695 5.84526 18.208 5.43126 18.622C5.01726 19.018 4.51326 19.216 3.91926 19.216C3.30726 19.216 2.79426 19.018 2.38026 18.622C1.98426 18.208 1.78626 17.695 1.78626 17.083C1.78626 16.489 1.98426 15.985 2.38026 15.571C2.79426 15.157 3.30726 14.95 3.91926 14.95C4.51326 14.95 5.01726 15.157 5.43126 15.571C5.84526 15.985 6.05226 16.489 6.05226 17.083Z"
                        fill="#6E6E6E"
                      />
                    </svg>
                  </Link>
                </li>
                {isAuthenticated && (
                  <li class="nav-item">
                    <CashierModals {...props} />
                  </li>
                )}

                {isAuthenticated && (
                  <li class="nav-item">
                    <UserDropDownMenu {...props} />
                  </li>
                )}
                {!isAuthenticated && (
                  <li class="nav-item">
                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      onClick={() => {
                        props.setModalLoginPropsData(true);
                        setTimeout(function () {
                          props.setModalLoginPropsData(false);
                        }, 500);
                      }}
                      className="common_btn_v1 deep"
                    >
                      {__("Log In")}
                    </Link>
                  </li>
                )}

                {!isAuthenticated && (
                  <li class="nav-item">
                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn_v1"
                    >
                      {__("Register")}
                    </Link>
                  </li>
                )}
                <li class="nav-item">
                  <LanguageSwitcher />
                </li>
                <div className="clearfix clear"></div>
              </ul>
              <div className="clearfix clear"></div>
            </div>
          </div>
        </nav>
        {/* <RightSidebar
          userBalance={props.userBalance}
          userDetails={props.userDetails}
          accountProgress={props.accountProgress}
          rightSidebarOpen={rightSidebarOpen}
          toggleRightSidebar={toggleRightSidebar}
        /> */}
        <SupportSidebar {...props} />
      </header>
    </>
  );
};

export default Header;
