import logo from "./logo.svg";
import "./App.scss";
// import  "dis/bootstrap/less/bootstrap.less";
import React from "react";
import { OpenRoutes, PrivateRoutes } from "./routes/router";
import auth from "services/auth";
import Spinner from "elements/Spinner";
import AppContext from "contexts/AppContext";
import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";

function App(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const appContext = React.useMemo(
    () => ({
      updateUserObj: async (data) => {
        // console.log("UserUpdated", data);
        await checkLoggedIn();
      },
    }),
    []
  ); 

  const checkLoggedIn = async () => {
    var authenticated = await auth.isAuthenticated();
    if (authenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const init = async () => {
    await checkLoggedIn();
    auth.checkLastUsed();
    setInterval(() => {
      auth.updateLastUsed();
    }, 5000);
    setIsReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  if (isReady) {
    return (
      <>
        <Helmet>
          <title>{__("Genie Jackpot | Join today and receive up to $2,500 extra to play with!")}</title>
          <meta name="keywords" content={require("elements/Keywords").default} />
          <meta
            name="description"
            content={__("The number 1 place for crypto casino excitement and fun. Join now and win BIG!")}
          />
          <link rel="canonical" href={"https://casinomonteolympus.com" + window.location.pathname} />
          <meta
            property="og:title"
            content={__("Genie Jackpot | Join today and receive up to $2,500 extra to play with!")}
          />
          <meta
            property="og:description"
            content={__("The number 1 place for crypto casino excitement and fun. Join now and win BIG!")}
          />
          <meta
            property="og:image"
            content={"https://casinomonteolympus.com" + require("assets/images/logos/casino-logo.png").default}
          />
          <meta
            property="twitter:title"
            content={__("Genie Jackpot | Join today and receive up to $2,500 extra to play with!")}
          />
          <meta
            property="twitter:description"
            content={__("The number 1 place for crypto casino excitement and fun. Join now and win BIG!")}
          />
          <meta
            property="twitter:image"
            content={"https://casinomonteolympus.com" + require("assets/images/logos/casino-logo.png").default}
          />
        </Helmet>
        <AppContext.Provider value={appContext}>
          {!isLoggedIn && <OpenRoutes {...props} />}
          {isLoggedIn && (
            <React.Fragment>
              <PrivateRoutes {...props} />
            </React.Fragment>
          )}
        </AppContext.Provider>
      </>
    );
  } else {
    return <Spinner />;
  }
}

export default App;
