import React, { useState, useEffect, useCallback, useContext } from "react";
import ConnectWithMetamask from "elements/dashboard_elements/NftHolders/ConnectWithMetamask";
import AuthedPages from "elements/dashboard_elements/NftHolders/AuthedPages";
import AppContext from "contexts/AppContext";
import api from "services/api";
import ls from "services/ls";

export default function NftHolders(props) {
  const { updateUserObj } = useContext(AppContext);

  const metamaskConnected = props.metamaskConnected;
  const setMetamaskConnected = props.setMetamaskConnected;

  const getAuthenticated = async () => {
    props.setSpinner(true);
    var localConnected = await ls.get("metamaskConnected");
    var response = await api.get("/nft/isauthenticated");
    props.setSpinner(false);
    if (response.status === 200 && localConnected) {
      setMetamaskConnected(true);
    } else {
      setMetamaskConnected(false);
    }
  };

  useEffect(() => {
    getAuthenticated();
    window.ethereum?.on("accountsChanged", async () => {
      ls.set("metamaskConnected", false);
      setMetamaskConnected(false);
    });
  }, []);

  return (
    <>
      {metamaskConnected ? (
        <AuthedPages {...props} />
      ) : (
        <ConnectWithMetamask {...props} />
      )}
    </>
  );
}
