import React from "react";
import Translator, { __ } from "services/Translator";

export default function VipRakeback() {
  return (
    <div className="container">
      <div className="vip_rewards rakeback">
        <div className="heading text-left">
          {__("Blizz Rakeback")}
        </div>
        <div className="title text-left">
          {__(
            "Blizz Rakeback is our little way of saying…you’re valued. We hope you know that, this is us proving it. Level up and get increased daily, weekly and monthly cashback, so if you are up you are happy, if you are not you still get a little something extra back, courtesy of Blizz!"
          )}
        </div>
        {/*Asked to removed*/}
        <div className="single_rakeback">
          <div className="row align-items-center">
            <div className="col-md-3 text-center">
              <img
                src={require("assets/images/v1/vip/daily-rakeback.svg").default}
              />
            </div>
            <div className="col-md-9">
              <div className="title small_heading text-left">
                {__("Daily Rakeback")}
              </div>
              <div className="title text-left padding_top_0">
                {__(
                  "Get up to 10% cashback from your previous days play by spinning the Blizz Cashback wheel. Every day you play and finish behind, you are guaranteed some funds back the next day!"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="single_rakeback">
          <div className="row align-items-center">
            <div className="col-md-3 text-center">
              <img
                src={
                  require("assets/images/v1/vip/weekly-rakeback.svg").default
                }
              />
            </div>
            <div className="col-md-9">
              <div className="title small_heading text-left">
                {__("Weekly Rakeback")}
              </div>
              <div className="title text-left padding_top_0">
                {__(
                  "The more you play, the more you will level up. Level up far enough, and the 10% all players that are $250 or more down get back on a Monday will be increased, just for you!"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="single_rakeback">
          <div className="row align-items-center">
            <div className="col-md-3 text-center">
              <img
                src={require("assets/images/v1/vip/monthly-rakeback.svg").default}
              />
            </div>
            <div className="col-md-9">
              <div className="title small_heading text-left">
                {__("Monthly Bonus")}
              </div>
              <div className="title text-left padding_top_0">
                {__(
                  "Play enough and get a guaranteed monthly bonus based on your play each and every month you are behind. Just another extra as a thank you for your custom at Blizz!"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
