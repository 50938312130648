import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/games_elements/GameItem";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";
import { Badge, Table, Modal, Collapse } from "react-bootstrap";
import api from "services/api";
import Translator, { __ } from "services/Translator";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const [providersModal, setProvidersModal] = useState(false);
  const closeAllModals = () => {
    setProvidersModal(false);
  };
  const toggleProviersModal = () => {
    setProvidersModal(!providersModal);
  };
  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };
  const __ = (text) => text;

  return (
    <div className="category_games_area">
      {props.categoryName !== "" && (
        <div className="category_games_header">
          <div className="headline_left">
            <h4 className="game_headline">{props.categoryName}</h4>
            {props.isMobileDevice ? <></> : <></>}
          </div>
        </div>
      )}

      <div class="game_list_v1">
        {props.gameList.map((game, index) => (
          <GameItem
            game={game}
            isAuthenticated={props.isAuthenticated}
            key={index}
          />
        ))}
      </div>
      {props.showPagination && (
        <>
          <LoadMore data={props.pagination} onPageChange={props.onPageChange} />
          <br />
        </>
      )}

      <Modal size="lg" show={providersModal} onHide={closeAllModals}>
        <Modal.Body className="providers_modal">
          <div onClick={closeAllModals} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">{__("Filter By Providers")}</div>
          <div className="providers_list">
            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              onClick={clearAllProviders}
              className={
                "common_btn small " +
                (selectedProviders.length === 0 ? "active" : "inactive")
              }
              to="#"
            >
              {__("All Providers")}
            </Link>
            {providers.map((item, index) => (
              <Link
                hrefLang={Translator.getSelectedLanguage().code}
                onClick={() => toggleSelectedProvider(item.provider_id)}
                key={index}
                className={
                  "common_btn small " +
                  (selectedProviders.indexOf(item.provider_id) !== -1
                    ? "active"
                    : "inactive")
                }
                to="#"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
